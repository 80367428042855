import React, { useMemo, useState } from "react";
import { getTranslation } from "./landing";
import { arrowDownIcon, arrowUpIcon, faqs } from "./data";

const FaqSection = () => {
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [seeMore, setSeeMore] = useState(false);

  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  const updatedFaqs = useMemo(() => {
    if (seeMore) {
      return faqs;
    }
    return faqs.filter((_, index) => index < 3);
  }, [seeMore]);

  return (
    <div className="my-16 flex flex-col gap-4 justify-center items-center w-full">
      <div className="faq-section lg:w-[800px]">
        <div className="lg:flex items-center gap-1">
          {/*<span className="bybest-title-faq">*/}
          {/*  {getTranslation("frequently")}*/}
          {/*</span>{" "}*/}
          <strong className="bybest-title-faq">
            {getTranslation("faqTitle")}
          </strong>
        </div>
        <div className="space-y-4 w-full">
          {updatedFaqs.map((faq, index) => (
            <div
              key={index}
              className={`flex flex-col items-start justify-between w-full ${
                expandedFaq !== index && index !== updatedFaqs.length - 1
                  ? "border-b border-b-[#e5e5e5]"
                  : ""
              }`}
            >
              <div
                onClick={() => toggleFaq(index)}
                className="cursor-pointer w-full text-left lg:flex justify-between text-lg font-bold text-black py-4"
              >
                <span className="title">{faq.question}</span>
                <span className="hidden md:block">
                  {expandedFaq === index ? arrowUpIcon : arrowDownIcon}
                </span>
              </div>
              {expandedFaq === index && (
                <p className="description w-3/4">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="text-center mt-8">
        <button
          className="bg-red-600 text-white font-bold py-3 px-6 rounded hover:bg-red-700"
          onClick={() => setSeeMore(!seeMore)}
        >
          {seeMore
            ? getTranslation("viewLessFaqsButton")
            : getTranslation("viewAllFaqsButton")}
        </button>
      </div>
    </div>
  );
};

export default FaqSection;
