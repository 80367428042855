import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import Visa from "../../../assets/images/payment-cards/VISA.png";
import Mastercard from "../../../assets/images/payment-cards/Mastercard.png";
import Amex from "../../../assets/images/payment-cards/AMEX.png";
import Discover from "../../../assets/images/payment-cards/319.png";
import CreditCardCvc from "../../../assets/images/payment-cards/creditCardCvc.png";
import { MdOutlineClose } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import "./checkout.css";
import { t } from "i18next";

const getTranslation = (label = "") => t(`bybest.checkout-modal.${label}`);

const PaymentFormModal = ({ showModal, onClose, value = "1440" }) => {
  const [open, setOpen] = useState(false);
  const [cardNum, setCardNum] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const onSuccessToken = (data) => {
    const token = data?.response?.token?.token ?? "";
    onClose(token);
  };

  const onErrorToken = (error) => {
    NotificationManager.error(
      error?.message || getTranslation("errorMessage"),
      getTranslation("errorTitle"),
      3000
    );
  };

  const handleSubmit = () => {
    if (window.TCO) {
      var args = {
        sellerId: "255167442907",
        publishableKey: "8F2CC48C-6614-44D6-AD9D-3515ABE92BB5",
        ccNo: "4111111111111111",
        cvv: "124",
        expMonth: "12",
        expYear: "24",
        name: "John Doe",
      };

      window.TCO.loadPubKey("production", () => {
        window.TCO.requestToken(onSuccessToken, onErrorToken, args);
      });
    } else {
      NotificationManager.error(
        getTranslation("libraryErrorMessage"),
        getTranslation("errorTitle"),
        3000
      );
    }
  };

  return (
    <Modal
      centered
      closeIcon={false}
      footer={null}
      wrapClassName="bybest-checkoutmodal"
      open={open}
      onCancel={() => onClose()}
      modalRender={(modal) => (
        <div style={{ borderRadius: 8, overflow: "hidden" }}>{modal}</div>
      )}
    >
      <div className="flex flex-col justify-center items-center w-full !bg-[#F2F2F2]">
        <div className="flex justify-center items-center w-full py-3 px-4 border-b border-b-[#DEE2E6]">
          <h1 className="flex-1 text-[20px] font-bold leading-[30px] text-[#000]">
            {getTranslation("cardPayment")}
          </h1>
          <MdOutlineClose size={22} onClick={() => onClose()} />
        </div>
        <div className="w-full p-4">
          <div className="grid grid-cols-2 gap-6 items-center">
            <div className="flex flex-col gap-2 col-span-2">
              <span className="checkout-label">
                {getTranslation("cardNumber")}
              </span>
              <input
                required
                value={cardNum}
                onChange={(e) => setCardNum(e.target.value)}
                className="card-number col-span-2"
                placeholder="1234 1234 1234 1234"
                style={{
                  paddingRight: "140px",
                  backgroundImage: `url(${Visa}), url(${Mastercard}), url(${Amex}), url(${Discover})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition:
                    "right 95px center, right 65px center, right 35px center, right 5px center",
                }}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="checkout-label">
                {getTranslation("expirationDate")}
              </span>{" "}
              <input
                required
                className="card-number"
                placeholder="MM / YY"
                value={expiry}
                onChange={(e) => setExpiry(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="checkout-label">
                {getTranslation("securityCode")}
              </span>{" "}
              <input
                required
                name="cvv"
                className="card-number"
                placeholder="CVC"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                style={{
                  paddingRight: "100px",
                  backgroundImage: `url(${CreditCardCvc})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right 10px center",
                  backgroundSize: "20px 20px",
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className="w-full mt-6 rounded bg-[#000] h-[45px] font-bold text-[14px] text-white"
            onClick={handleSubmit}
          >
            {`${getTranslation("pay")} ${value} ${getTranslation("all")}`}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentFormModal;
