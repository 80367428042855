import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { footerSections, hasPage } from "../../containers/footer/data";

const ByBestSidebar = () => {
  const { app_key = "BYB2802APPY" } = useParams();

  const navigate = useNavigate();

  const goTo = (url = "") => {
    if (hasPage.includes(url)) {
      navigate(`/page/${url}`);
    } else {
      navigate(`/${url}`);
    }
  };

  return (
    <div className="hidden md:flex flex-col items-start justify-start col-span-2">
      {footerSections.map((section, index) => (
        <div key={index}>
          <h3 className="text-[14px] leading-[21px] font-bold text-[#000] uppercase mt-5">
            {section.title}
          </h3>
          {section.links.map((link, idx) => (
            <div
              key={idx}
              className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase cursor-pointer"
              onClick={() => goTo(link.href)}
            >
              {link.name}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ByBestSidebar;
