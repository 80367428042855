import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { t } from "i18next";
import Offers from "../../assets/images/retail/offers.png";
import { SaleSection } from "../../containers/header/sales-section";

const BybestDropdownMenu = ({ menu }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsCollapsed(false);
  }, [pathname]);

  const goCategory = (category, item) => {
    if (menu.label === "Blog") {
      navigate(`/blog/category/${item}`);
    } else {
      navigate(`/category/${item.replace(/\s+/g, "-").toLowerCase()}`, {
        state: {
          category,
          item,
        },
      });
    }
  };

  const exploreOffers = () => {
    if (menu?.id === 11) {
      navigate(`/group/10`);
    }
  };

  return (
    <div className="relative" onClick={exploreOffers}>
      <div className={`flex items-center ${isCollapsed ? "bg-[#f4f4f4]" : ""}`}>
        <span
          onMouseEnter={() => setIsCollapsed(true)}
          onMouseLeave={() => setIsCollapsed(false)}
          className={`uppercase text-[13px] leading-[20px] !font-extrabold text-black whitespace-nowrap cursor-pointer py-[13px] px-[19px] ${
            menu.id === 11 || menu.id === 10
              ? "!text-[#E14848]"
              : "hover:!text-[#ffc107]"
          }`}
        >
          {menu.label}
        </span>

        {menu.id === 11 && <img alt="" src={Offers} />}
      </div>
      {isCollapsed && menu.menus.length > 0 && (
        <>
          {menu.menu_image == null && (
            <div
              className="fixed top-[167px] overflow-hidden transition-all duration-100 delay-100 w-[150px]"
              onMouseLeave={() => setIsCollapsed(false)}
              onMouseEnter={() => setIsCollapsed(true)}
            >
              <div className="grid grid-cols-2">
                <ul className="flex flex-col justify-between gap-5 items-start text-18 bg-[#f4f4f4] px-5 py-2 w-fit">
                  {menu.menus.map((item, i) => (
                    <li
                      key={i}
                      onClick={() => goCategory(item.category, item.label)}
                      className="py-1 w-full text-[12px] text-[#000] hover:!text-[#ffc107] font-extrabold opacity-60 hover:font-bold whitespace-nowrap uppercase cursor-pointer"
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {menu.menu_image != null && (
            <div className="w-[100vw] fixed top-[167px] left-0 right-0 flex justify-center items-center overflow-hidden transition-all duration-100 delay-100">
              <div
                className={`w-full max-w-screen-main bg-[#f4f4f4] p-5 grid grid-cols-6 gap-8 ${
                  menu.id === 10 ? "grid grid-cols-9 gap-2" : ""
                }`}
                onMouseLeave={() => setIsCollapsed(false)}
                onMouseEnter={() => setIsCollapsed(true)}
              >
                <div>
                  <img
                    src={menu.menu_image}
                    className="w-full h-full object-contain"
                    alt="icon"
                  />
                </div>
                {menu.menus.map((item, i) => (
                  <div className="flex flex-col items-start" key={i}>
                    <p className="text-[12px] font-bold text-[#000] uppercase">
                      {item.category}
                    </p>
                    {item?.items?.map((_item, i) => {
                      return (
                        <div
                          key={i}
                          className="flex justify-start items-center cursor-pointer py-1 text-[12px] text-[#000] hover:!text-[#ffc107] font-medium hover:font-bold uppercase"
                          onClick={() => goCategory(item.category, _item)}
                        >
                          {_item}
                          {(_item === "Flik flak" || _item === "SWATCH") && (
                            <img
                              alt=""
                              src="https://bybest.shop/assets/img/icons/swiss.png"
                              className="ml-1 h-[13px] object-contain"
                            />
                          )}
                        </div>
                      );
                    })}
                    <p className="text-[13px] cursor-pointer font-bold text-[#000] uppercase hover:text-[#ffc107]">
                      {t("bybest.header.see_all")}
                    </p>
                  </div>
                ))}
                {menu.id === 10 && <SaleSection />}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BybestDropdownMenu;
