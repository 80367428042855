import React from "react";
import "./index.css";
import lineImg from "../../../assets/images/pink-line.webp";
import { t } from "i18next";

const list = [
  {
    title: "Intuitive Operations",
    content:
      "Simplify Tasks, Enhance Efficiency. VenueBoost’s user-friendly design streamlines complex operations, making management a breeze for venues big and small.",
  },
  {
    title: "Instant Efficiency",
    content:
      "Zero Downtime, Full Speed Ahead. Dive into VenueBoost’s features immediately, no training required. Designed for quick adaptation, we ensure a seamless start.",
  },
  {
    title: "Tailored Onboarding",
    content:
      "Smooth Start, Custom Fit. Our personalized onboarding service ensures you quickly benefit from VenueBoost’s full suite, making your transition smooth and successful.",
  },
  {
    title: "Simplified Financials",
    content:
      "Effortless Transactions, Increased Profits. VenueBoost simplifies your financial operations with secure, easy-to-use payment solutions, turning goodbye to complexity into hello to profitability.",
  },
];

export const getSwitchTranslation = (label = "") =>
  t(`home.switching.${label}`);

const Switching = () => {
  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <div className="bg-[#F3F3F3] w-[100vw] relative">
        <div className="mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pt-8 md:pt-20 pb-8 md:pb-24">
            <div className="flex flex-col justify-between">
              <span className="text-primary1 font-bold text-28 md:text-7xl text-center md:text-left">
                {getSwitchTranslation("title")}
              </span>
              <div className="hidden md:block h-1 w-28">
                <img
                  alt={"VenueBoost Platform"}
                  src={lineImg}
                  className="w-full h-full"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black text-sm md:text-[19px] font-medium max-w-[360px] mt-8 md:mt-0">
                  {getSwitchTranslation("description")}
                </span>
              </div>
              <a
                aria-label={getSwitchTranslation("unlockFreePlan")}
                href="/get-started"
                className="hidden get-started rounded-md w-max px-9 h-[54px] md:flex items-center"
              >
                <span className="text-base text-gray2 font-black">
                  {getSwitchTranslation("unlockFreePlan")}
                </span>
              </a>
            </div>
            <div className="col-span-1 md:col-span-2 flex flex-row flex-wrap gap-x-10 gap-y-4 md:gap-y-16">
              {list.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col switch-item rounded-2xl max-w-[375px] px-8 py-8 gap-2"
                >
                  <span className="text-pink1 font-bold text-2xl md:text-30 leading-9 max-w-[210px]">
                    {item.title}
                  </span>
                  <span className="text-sm md:text-base text-black2">
                    {item.content}
                  </span>
                </div>
              ))}
            </div>
            <a
              aria-label={getSwitchTranslation("unlockFreePlan")}
              href="/get-started"
              className="md:hidden get-started rounded-md w-full px-9 h-[54px] flex items-center justify-center"
            >
              <span className="text-base text-gray2 font-black">
                {getSwitchTranslation("unlockFreePlan")}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Switching;
