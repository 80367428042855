import React from "react";
import { getTranslation } from "./landing";
import { loyaltyLevels } from "./data";
import "./landing.css";
import OrangeImage from "../../../assets/images/landing/orange-image.png";
import GrayImage from "../../../assets/images/landing/gray-image.png";
import { useNavigate, useParams } from "react-router-dom";

const LoyaltyProgram = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();

  return (
    <div className="mt-16 loyalty-section">
      <div className="flex flex-col items-start justify-center gap-5">
        <div className="w-full flex justify-center items-center">
          <span className="moreYouShop">{getTranslation("moreYouShop")}</span>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="lg:flex items-center gap-1">
            {/*<span className="bybest-title-size">*/}
            {/*  {getTranslation("loyaltyProgramTitle")}*/}
            {/*</span>{" "}*/}
            <strong className="bybest-title-size">
              {getTranslation("peaksofprivilege")}
            </strong>
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <span className="decription">
            {getTranslation("loyaltyProgramDescription")}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mt-10">
        {loyaltyLevels.map((level, index) => (
          <div
            key={index}
            className={`loyalty-level ${
              index % 2 === 0 ? "bg-[#fff1df]" : "bg-[#f9f9ff]"
            }`}
          >
            <span
              className="round"
              style={{
                background: `url(${index % 2 === 0 ? OrangeImage : GrayImage})`,
              }}
            />
            <span
              className={`name ${
                index % 2 === 0 ? "!text-[#c1763c]" : "!text-[#A9AAAE]"
              }`}
            >
              {level.name}
            </span>
            <span className="title">{level.description}</span>
            <span className="benefits">{level.benefits}</span>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-center mt-8">
        <div>
          <button
            className="bg-[#3b3b3a] text-white font-bold py-2 px-4 rounded"
            onClick={() => navigate(`/my-club`)}
          >
            {getTranslation("loyaltyDetailsButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyProgram;
