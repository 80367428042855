import React from "react";

const HeroSection = ({
  title = "",
  subtitle1 = "",
  subtitle2 = "",
  description = "",
  buttonText = "",
  buttonAction,
  backgroundImage,
  isHero = true,
}) => {
  return (
    <div className="w-full bg-cover bg-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full">
        <div className="flex flex-col justify-center items-start lg:py-10 p-2">
          <h5 className="text-22 font-bold mb-4 hero-text-1">{title}</h5>
          {isHero ? (
            <>
              <h1 className="text-4xl mb-2 hero-text-2">{subtitle1}</h1>
              <h1 className="text-4xl mb-4 hero-text-3">{subtitle2}</h1>
              <p className="text-lg mb-8 hero-text-4">{description}</p>
              <div className="flex">
                <button className="uppercase" onClick={buttonAction}>
                  {buttonText}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-4">
                <div>
                  <span className="bybest-title-size-40">{subtitle1}</span>
                  {"  "}
                  <span className="bybest-title-size-40">{subtitle2} <b>By Best Shop?</b></span>
                </div>
                <p className="bybest-footer-description mb-8">{description}</p>
              </div>
              <div className="flex">
                <button onClick={buttonAction}>{buttonText}</button>
              </div>
            </>
          )}
        </div>

        <div
          className="bg-cover bg-center"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            minHeight: 540,
          }}
        />
      </div>
    </div>
  );
};

export default HeroSection;
