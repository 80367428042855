import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

const BusinessServiceCard = ({ type, data }) => {
  const navigate = useNavigate();
  if (data == null) {
    return null;
  }

  const navigateToUrl = (url) => {
    navigate(url);
  };

  let containerClass = "";
  switch (type) {
    case "entertainment_venues":
      containerClass = "entertainmentVenuesBorderGradient";
      break;
    case "food":
      containerClass = "foodBorderGradient";
      break;
    case "accommodation":
      containerClass = "accommodationBorderGradient";
      break;
    case "retail":
      containerClass = "retailBorderGradient";
      break;
    default:
      break;
  }

  return (
    <div
      onClick={() => {
        navigateToUrl(data?.url);
      }}
      className={`flex flex-row justify-center md:justify-start cursor-pointer rounded-[13px] px-4 py-2 w-full items-center bg-white overflow-hidden `}
    >
      <img
        className="w-[50px] h-[50px] md:h-[50px] object-contain"
        src={data?.icon}
        alt=""
      />
      <div className=" px-3 md:px-4 flex flex-col justify-center">
        <p className="text-[#000000] text-20  md:text-20  font-semibold leading-[22px]">
          {data?.label}
        </p>
      </div>
    </div>
  );
};

export default BusinessServiceCard;
