import React from "react";
import { getTranslation } from "../landing/landing";
import { benefits, plusIcon } from "../landing/data";

const Benefits = () => {
  return (
    <div className="w-full md:p-4 flex flex-col justify-center gap-5 col-span-1 lg:col-span-6">
      <div className="xl:flex flex-wrap gap-1">
        {/*<span className="bybest-title-header">*/}
        {/*  {getTranslation("bybestshop")}*/}
        {/*</span>*/}

        <strong className="bybest-title-header">
          {getTranslation("memberPrivileges")}
        </strong>
      </div>

      <div className="w-full flex flex-col gap-4">
        {benefits.map((b) => (
          <div key={b.id} className="flex items-start xl:items-center gap-4">
            <div className="rounded-[50%] bg-[#f4f4f5] h-9 w-9">
              <span>{plusIcon}</span>
            </div>

            <div className="flex flex-col gap-2">
              <strong className="bybest-title !text-20">{b.title}</strong>
              <span className="benefits-title w-3/4">{b.subtitle}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
