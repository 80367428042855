export const bybestBrands = [
  {
    href: "https://bybest.shop/brand/swarovski",
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1654870085.png",
    alt: "Swarovski",
    maxHeight: "30px",
  },
  {
    href: "https://bybest.shop/brand/swatch",
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1644772840.png",
    alt: "Swatch",
    maxHeight: "50px",
  },
  {
    href: "https://bybest.shop/brand/villeroy-boch",
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1650011150.png",
    alt: "Villeroy & Boch",
    maxHeight: "40px",
  },
  {
    href: "https://bybest.shop/brand/blukids",
    src: "https://admin.bybest.shop/storage/brands/brands_colored_1657033286.png",
    alt: "Blukids",
    maxHeight: "40px",
  },
];
