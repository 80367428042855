import React from "react";
import "./landing.css";
import RegisterForm from "../components/register-form";
import Benefits from "../components/benefits";

const RegisterMember = ({
  swapSections = false,
  onSubmit,
  isMyClub,
  resetForm,
  isLoading = false,
}) => {
  return (
    <div className="mt-5 w-full grid grid-cols-1 lg:grid-cols-12 gap-8 items-center">
      {swapSections ? (
        <>
          <div className="w-full p-4 flex flex-col justify-center gap-5 col-span-1 lg:col-span-6">
            <Benefits />
          </div>

          <div className="hidden lg:block"></div>

          <RegisterForm
            onSubmit={onSubmit}
            isMyClub={isMyClub}
            resetForm={resetForm}
            isLoading={isLoading}
          />
        </>
      ) : (
        <>
          <RegisterForm
            onSubmit={onSubmit}
            isMyClub={isMyClub}
            resetForm={resetForm}
            isLoading={isLoading}
          />

          <div className="hidden xl:block"></div>

          <div className="w-full md:p-4 flex flex-col justify-center gap-5 col-span-1 lg:col-span-6">
            <Benefits />
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterMember;
