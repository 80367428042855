import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../../../redux/actions/auth";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import { NotificationManager } from "react-notifications";
import { t } from "i18next";
import WhiteLabelService from "../../../services/whiteLabelService";
import { getRegisterTranslation } from "../register-successful";

const ByBestVerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, app_key = "BYB2802APPY" } = useParams();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [errMsg, setErrMsg] = useState("");

  const vbToken = localStorage.getItem("vbToken");
  const registerToken = localStorage.getItem("registerToken");

  const onSubmit = () => {
    setLoading(true);
    WhiteLabelService.resendRegistration(
      {
        source: "bybest.shop_web",
      },
      app_key,
      registerToken
    )
      .then(() => {
        NotificationManager.success(t("bybest.email.resend"), "Success");
        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || "Something went wrong!",
          "Error",
          3000
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (vbToken) {
      navigate(`/login`);
      NotificationManager.success(t("bybest.email.confirmationEmail"));
    }
  }, [vbToken, app_key]);

  useEffect(() => {
    doVerifyEmail();
  }, [token]);

  const doVerifyEmail = () => {
    setLoading(true);
    dispatch(verifyEmail({ token }))
      .then(() => {
        setLoading(false);
        setHasError(false);
        setTimeout(() => {
          navigate(`/dashboard`);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setHasError(true);
        setErrMsg(error?.message || "Invalid link");
      });
  };

  return (
    <BBMainLayout clientKey="bybest">
      <div className={"align-col-middle view-terms my-10"}>
        <div className="w-full rounded-4xl p-14 gap-10">
          <div className="relative started-form">
            <div className="flex justify-center w-full ">
              {loading ? (
                <Spin />
              ) : !hasError ? (
                <div className="flex flex-col justify-center items-center">
                  <p className=" text-primary1 my-5 text-28 w-[60%] ms:w-full text-center">
                    {t("bybest.email.confirmationEmail")}
                  </p>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <p className=" text-red-600 my-5 text-28  text-center">
                    {errMsg}
                  </p>
                  <button
                    className="hover:bg-primary1 bg-primary1 text-white rounded-md font-normal text-18 mt-5  px-10"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <span className="flex flex-wrap">
                        <Spin />{" "}
                        <span>{getRegisterTranslation("detailsButton")}</span>
                      </span>
                    ) : (
                      getRegisterTranslation("detailsButton")
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestVerifyEmail;
