import React, { useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

const SingleSection = ({ order }) => {
  const [visibleSecond, setVisibleSecond] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/products/${order.id}/${order.slug}`);
  };

  return (
    <div
      className="bg-transparent rounded-lg overflow-hidden transition-transform transform cursor-pointer"
      onClick={handleClick}
    >
      <div
        className="image w-full"
        onMouseEnter={() => setVisibleSecond(true)}
        onMouseLeave={() => setVisibleSecond(false)}
      >
        <img
          src={visibleSecond ? order.images[1] : order.images[0]}
          alt={order.name}
        />
      </div>
      <div className="product-info max-w-[170px]">
        <p className="text-[12px] font-bold text-[#000] uppercase truncate">
          {order.name}
        </p>
        <p className="sub-title truncate uppercase">{order.subTitle}</p>
        <div className="price-details flex flex-wrap gap-1">
          <span className="old-price">{order.oldPrice}</span>
          <span className="discount">{order.discount}</span>
          <span className="new-price">{order.newPrice}</span>
        </div>
      </div>
    </div>
  );
};

export default SingleSection;
