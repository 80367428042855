import React, { useState } from "react";
import { CollapseMenu } from "./collapse-menu";
import { Drawer, Input } from "antd";
import { SearchOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { menuItems } from "./data";
import { changeBybestLanguage } from "../../redux/actions/app";
import { useDispatch, useSelector } from "react-redux";

const getTranslation = (label = "") => t(`bybest.${label}`);

const HeaderDrawer = ({ open, onClose, app_key = "BYB2802APPY" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.bybestLanguage);

  const [activeMenu, setActiveMenu] = useState();

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeBybestLanguage(newLanguage));
    window.location.reload();
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      open={open}
      width="100%"
      mask={false}
      zIndex={5}
      bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
    >
      <div className="w-full px-4 mt-[94px]">
        <Input
          placeholder={getTranslation("headerDrawer.searchPlaceholder")}
          prefix={<SearchOutlined fontSize="small" style={{ color: "#888" }} />}
          className="w-full border-none h-11 bg-[#e9e9e9]"
          styles={{ input: { backgroundColor: "#e9e9e9" } }}
        />
      </div>
      <div className="w-full flex flex-col justify-center items-center mt-4 border-t border-[#c5c5c5]">
        <CollapseMenu
          menu={{ label: getTranslation("headerDrawer.login"), items: [] }}
          onSelect={() => {
            onClose();
            navigate(`/login`);
          }}
        />
        {menuItems.map((menu) => (
          <CollapseMenu
            key={menu.label}
            menu={menu}
            isOpened={menu.label === activeMenu?.label}
            onSelect={() => {
              if (menu?.id === 11) {
                navigate(`/group/10`);
                onClose();
              }
              if (menu.label === activeMenu?.label) {
                setActiveMenu(null);
              } else {
                setActiveMenu(menu);
              }
            }}
            onCloseDrawer={onClose}
          />
        ))}
        <div className="w-full bg-[#f4f4f4] flex flex-col gap-2 items-start py-2">
          <div className="w-full px-4 bg-[#f4f4f4] flex flex-col gap-2 items-start">
            <div>
              <p className="text-[#000] text-[14px] w-full">
                {getTranslation("headerDrawer.language")}:{" "}
                <select
                  className="choose-lang cursor-pointer bg-transparent outline-none border-none"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <option value="al">AL</option>
                  <option value="en">EN</option>
                </select>
              </p>
            </div>
            <div>
              <p className="text-[#000] text-[14px] w-full">
                {getTranslation("headerDrawer.currency")}:{" "}
                <span className="font-bold">LEK</span>
              </p>
            </div>
            <div className="w-full"></div>
          </div>
          <CollapseMenu
            menu={{ label: getTranslation("headerDrawer.myClub"), items: [] }}
            onSelect={() => {
              onClose();
              navigate(`/my-club`);
            }}
            className="bg-[#f4f4f4] !py-0"
          />
        </div>
      </div>
    </Drawer>
  );
};

export default HeaderDrawer;
