import React from 'react';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import './index.css';
import { t } from 'i18next';

export const getDownloadTranslation = (label = '') =>
  t(`bybest.downloadApp.${label}`);

const platforms = [
  {
    name: 'Android',
    icon: <AndroidOutlined style={{ fontSize: 48 }} className="mb-4" />,
    downloadText: 'Play Store',
    link: 'https://play.google.com/store',
    iconClass: 'fa-brands fa-android',
    downloadIconClass: 'fa-brands fa-google-play',
  },
  {
    name: 'iOS',
    icon: <AppleOutlined style={{ fontSize: 48 }} className="mb-4" />,
    downloadText: 'App Store',
    link: 'https://www.apple.com/app-store/',
    iconClass: 'fa-brands fa-apple',
    downloadIconClass: 'fa-brands fa-apple',
  },
];

const AppDownload = () => {
  return (
    <div className="flex flex-col items-center py-10 bg-white w-full px-5 lg:px-0">
      <p className="text-lg mb-8">
        Bybest Shop mobile app is available for Android and iOS.
      </p>
      <div className="flex flex-col sm:flex-row gap-6 w-full">
        {platforms.map((platform, index) => (
          <div
            key={index}
            className="col-lg-6 col-md-6 col-sm-12 text-center w-full"
          >
            <div className="platform-card bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="flex flex-col items-center justify-center mb-4">
                {platform.icon}
                <div className="mt-2 text-xl">{platform.name}</div>
              </div>

              <div className="flex items-center justify-center">
                <a
                  href={platform.link}
                  className="download-link flex items-center bg-black text-white py-2 px-4 rounded-lg font-bold"
                >
                  <i
                    className={`${platform.downloadIconClass} text-xl mr-2`}
                  ></i>
                  <div className="text-left">
                    <div className="text-sm">Download on the</div>
                    <div className="text-lg font-semibold">
                      {platform.downloadText}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppDownload;
