import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
// import Geocode from "react-geocode";

import "./index.css";
import { isMobile } from "react-device-detect";

const MapContainer = (props) => {
  const showSearch = props.showSearch ?? true;
  const [map, setMap] = useState(null);
  const [fromFood, setFromFood] = useState(false);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [lng, setLng] = useState();
  const [lat, setLat] = useState();
  const [address, setAddress] = useState("");

  const onMarkerClick = (props, marker, e) => {
    setActiveMarker(marker);
    setSelectedPlace(props);
    setShowingInfoWindow(true);
  };
  useEffect(() => {
    if (props.fromFood) {
      setFromFood(true);
    }
    if (props?.address) {
      if (showSearch) {
        handleStringLocation();
      } else {
        getAddressFromCoordinates(
          props.location.latitude,
          props.location.longitude
        );
        setLat(props.location.latitude);
        setLng(props.location.longitude);
      }
    }
  }, [props, showSearch]);

  const handleStringLocation = async () => {
    console.log("testtt");
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          props?.address?.address_line1 ?? props?.address
        )}&key=AIzaSyANrrODv_Yku21BTu398EtAjWcfn1U4VNY`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setLat(lat);
        setLng(lng);
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
    }
  };

  const getAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyANrrODv_Yku21BTu398EtAjWcfn1U4VNY`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      }
    } catch (error) {
      console.log("Error fetching address");
    }
  };

  const onMapClicked = (props) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  if (fromFood) {
    return (
      <div
        style={{
          height: "45vh",
          display: isMobile ? "inline" : "flex",
          flexDirection: "column",
        }}
      >
        <Map
          google={props.google}
          zoom={16}
          initialCenter={{ lat: lat, lng: lng }}
          onReady={(mapProps, map) => setMap(map)}
          onClick={onMapClicked}
          className="googlemap"
        >
          <Marker
            position={{
              lat,
              lng,
            }}
            onClick={onMarkerClick}
            name={"Current location"}
          />
        </Map>
      </div>
    );
  } else {
    return (
      <Map
        google={props.google}
        zoom={20}
        initialCenter={{ lat, lng }}
        center={{ lat, lng }}
        onReady={(mapProps, map) => setMap(map)}
        onClick={onMapClicked}
        className="googlemap"
      >
        <Marker
          position={{
            lat,
            lng,
          }}
          onClick={onMarkerClick}
          name={"Current location"}
        />
      </Map>
    );
  }
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyANrrODv_Yku21BTu398EtAjWcfn1U4VNY",
})(MapContainer);
