import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { t } from "i18next";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";

export const getPrivacyTranslation = (label = "") =>
  t(`bybest.privacy.${label}`);

const ByBestPrivacyPolicy = () => {
  const { pageSEO } = useSeoData();

  const collectionData = [
    "whatDataWeCollect",
    "howWeCollectData",
    "howWeUseData",
    "howWeStoreData",
    "marketing",
    "yourDataProtectionRights",
    "cookies",
    "howWeUseCookies",
    "typesOfCookies",
    "otherWebsitesPrivacyPolicies",
    "changesToPrivacyPolicy",
    "howToContactUs",
    "howToContactAuthorities",
  ];

  const generalInfo = ["personalInfo", "orderInfo"];

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="uppercase lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {`>`} {getPrivacyTranslation("title")}
          </p>
        </div>
        <div className="w-full max-w-screen-main mt-7 mb-5">
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className="col-span-7">
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("intro")}
              </p>

              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("topicsTitle")}:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                {collectionData.map((key) => (
                  <li
                    key={key}
                    className="text-[12px] leading-[20px] text-[#000]"
                  >
                    {getPrivacyTranslation(key)}
                  </li>
                ))}
              </ul>

              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPrivacyTranslation("whatDataWeCollectTitle")}
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("whatDataWeCollectDescription")}
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                {generalInfo.map((key) => (
                  <li
                    key={key}
                    className="text-[12px] leading-[20px] text-[#000]"
                  >
                    {getPrivacyTranslation(key)}
                  </li>
                ))}
              </ul>

              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPrivacyTranslation("howWeCollectDataTitle")}
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("howWeCollectDataDescription")}
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                {["registerOnSite", "surveyOrFeedback", "cookiesUsage"].map(
                  (key) => (
                    <li
                      key={key}
                      className="text-[12px] leading-[20px] text-[#000]"
                    >
                      {getPrivacyTranslation(key)}
                    </li>
                  )
                )}
              </ul>

              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPrivacyTranslation("howWeUseDataTitle")}
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("howWeUseDataDescription")}
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                {["processOrders", "sendSpecialOffers"].map((key) => (
                  <li
                    key={key}
                    className="text-[12px] leading-[20px] text-[#000]"
                  >
                    {getPrivacyTranslation(key)}
                  </li>
                ))}
              </ul>

              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPrivacyTranslation("howWeStoreDataTitle")}
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("howWeStoreDataDescription")}
              </p>

              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPrivacyTranslation("marketingTitle")}
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("marketingDescription")}
              </p>

              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPrivacyTranslation("yourDataProtectionRightsTitle")}
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]">
                {getPrivacyTranslation("yourDataProtectionRightsDescription")}
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                {[
                  "rightToCorrection",
                  "rightToRestrictProcessing",
                  "rightToObjectProcessing",
                  "rightToDataPortability",
                ].map((key) => (
                  <li
                    key={key}
                    className="text-[12px] leading-[20px] text-[#000]"
                  >
                    {getPrivacyTranslation(key)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestPrivacyPolicy;
