export const whitelabelSeoConfigs = {
    bybest: {
        name: "ByBest Shop",
        domain: "bybest.shop",
        defaultLanguage: "sq",
        analytics: {
            googleTagManager: 'GTM-KM2GQFDL',
            facebookPixel: '818330292990894',
            tiktokPixel: 'CQ203UJC77UF05LNANDG',
        },
        chat: {
            tawkTo: '6556249fd600b968d313a893/1hfc7u49l',
        },
        seo: {
            defaultTitle: 'ByBest Shop - Shopping with Style & Quality',
            defaultDescription: 'The first company in Albania that offers high quality products of the best brands. Get involved in an exclusive and convenient shopping experience!',
            defaultKeywords: 'bybest shop swarovski albania swatch klin flik flak blukids iana like villeroy boch sander mopita',
            defaultOgImage: 'https://bybest.shop/assets/img/bybest-logo.png',
            favicon: 'bybest/favicon.png',
            ico: 'bybest/favicon.png',

        },
        bodyScripts: {
            start: {
                matomo: true,
                matomoTagManager: true,
            },
            end: {
                zendesk: 'adafc6d2-4d22-426a-8865-aff99314a7a4',
                hotjar: {
                    hjid: 3100742,
                    hjsv: 6
                },
                googleAnalytics: 'G-LXWDW67GKK'
            }
        }
    },
    // You can add more clients here in the future
    // client2: { ... },
    // client3: { ... },
};

export const getWhitelabelSeoClientConfig = (clientKey) => {
    return whitelabelSeoConfigs[clientKey] || whitelabelSeoConfigs.bybest; // Default to ByBest if client not found
};