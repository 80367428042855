import React from "react";
import ByBestSidebar from "./sideBar";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";
import { t } from "i18next";

export const getPricesTranslation = (label = "") =>
  t(`bybest.priceAndCurrency.${label}`);

const ByBestPriceCurrency = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
        <p className="uppercase lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
          {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
          {">"} {getPricesTranslation("title")}
        </p>
      </div>
      <div className={`w-full max-w-screen-main mt-7 mb-5`}>
        <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
          <ByBestSidebar />
          <div className=" col-span-7">
            <p className="text-[13px] leading-[20px] text-[#000]  ">
              {getPricesTranslation("publishedPrices")}
            </p>
            <p className="mt-6 text-[13px] leading-[20px] text-[#000]  ">
              {getPricesTranslation("fees")}{" "}
              <span className="text-[#666]">
                {getPricesTranslation("convertedSum")}
              </span>
            </p>
            <p className="text-[13px] leading-[20px] text-[#000]  ">
              {getPricesTranslation("convertPolicy")}
            </p>
            <p className="text-[13px] leading-[20px] text-[#000] font-bold ">
              {getPricesTranslation("transportPrice")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ByBestPriceCurrency;
