import React, { useState } from "react";

import "./index.css";
import headerImg from "../../../assets/images/new-home-header.webp";

import { Modal } from "antd";
import { t } from "i18next";

const Header = () => {
  const [visibleYtVideoModal, setVisibleYtVideoModal] = useState(false);

  const text = (label) => t(`home.header.${label}`);

  return (
    <>
      <div className={"align-col-middle view-terms mt-0"}>
        <div className="w-[100vw] relative  ">
          <div className="mx-auto max-w-screen-main px-6 md:px-8 lg:px-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10 py-6 md:py-10">
              <div className="flex flex-col py-0 md:py-8">
                <span className="text-pink1 text-3xl md:text-[60px] font-black leading-10 md:leading-[80px]">
                  {text("masterYourVenue")}
                </span>
                <span className="text-primary1 text-3xl md:text-[60px] font-black leading-10 md:leading-[80px]">
                  {text("ownYourTime")}
                </span>
                <span className="hidden md:block text-black text-2xl font-medium mt-4 max-w-[490px] leading-7">
                  {text("transform")} {text("withVenueBoost")}{" "}
                  {text("venueManagementSoftware")} {text("discover")}
                </span>
                <div className="flex flex-col md:flex-row justify-center md:justify-start items-center">
                  <a
                    aria-label={text("get_started")}
                    href="/get-started"
                    className="get-started rounded-md w-max px-9 h-[54px] hidden md:flex items-center mt-20"
                  >
                    <span className="text-base text-gray2 font-black">
                      {text("get_started")}
                    </span>
                  </a>
                  <a
                    style={{ border: "1px solid" }}
                    className="rounded-md px-9 h-[54px] flex items-center justify-center ml:0 md:ml-5 mt-20 cursor-pointer text-[#240B3B] hidden md:flex font-semibold text-14 md:text-16"
                    onClick={() => setVisibleYtVideoModal(true)}
                  >
                    {text("howItWorks")} {">"}
                  </a>
                </div>
              </div>
              <div className="w-full md:w-[625px]">
                <img
                  alt={"VenueBoost - Master Your Venue,\n" + "Own Your Time"}
                  src={headerImg}
                />
              </div>
              <span className="blockk md:hidden text-black text-sm font-medium mt-2 max-w-[490px]">
                {text("transform")} {text("withVenueBoost")}{" "}
                {text("venueManagementSoftware")} {text("discover")}
              </span>
              <a
                aria-label="Unlock Free Plan"
                href="/get-started"
                className="get-started rounded-md w-full px-9 h-[54px] flex md:hidden items-center justify-center"
              >
                <span className="text-base text-gray2 font-black">
                  {text("get_started")}
                </span>
              </a>
              <a
                style={{ border: "1px solid" }}
                className="rounded-md w-full px-9 h-[54px] flex items-center justify-center ml:0 md:ml-5 md:mt-0 text-center cursor-pointer text-[#240B3B]  md:hidden font-semibold text-14 md:text-16"
                onClick={() => setVisibleYtVideoModal(true)}
              >
                {text("howItWorks")} {">"}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={visibleYtVideoModal}
        maskClosable={true}
        className="ant-modal-transparent"
        footer={null}
        closeIcon={<span></span>}
        onCancel={() => setVisibleYtVideoModal(false)}
        width={840}
      >
        <div className={"flex flex-col py-4 w-full h-max  overflow-auto gap-5"}>
          <iframe
            height="450"
            src="https://www.youtube.com/embed/qtRFNj1eOqg?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            className="css-1gku5g8"
          ></iframe>
        </div>
      </Modal>
    </>
  );
};

export default Header;
