import { t } from "i18next";
import { lastOrders } from "./data";
import SingleSection from "./single-section";
import "./index.css";

export const SaleSection = () => {
  const getText = (label = "") => t(`bybest.sales.${label}`);
  return (
    <div className="w-full col-span-6 col-item flex flex-col gap-3">
      <p className="title uppercase">{getText("recentlyOnSale")}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {lastOrders.map((item, index) => (
          <SingleSection order={item} key={index} />
        ))}
      </div>
    </div>
  );
};
