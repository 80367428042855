import React, { useEffect } from "react";
import BBHead from "./BBHead";
import BBBodyStart from "./BBBodyStart";
import BBBodyEnd from "./BBBodyEnd";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLanguage } from "../../../../utils/common";

const BBMainLayout = ({ children, clientKey = "bybest", seo = {} }) => {
  const language = useSelector((state) => state.app.bybestLanguage);
  const { app_key = "BYB2802APPY" } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const lang = getLanguage(language);
    const isHomepage = pathname === `` || pathname === `/${lang}`;

    let newPath;
    if (isHomepage) {
      newPath = ``;
    }

    // Navigate only if newPath is different from pathname
    if (pathname !== newPath) {
      navigate(newPath, { replace: true });
    }
  }, [pathname, language, navigate, app_key]);

  return (
    <>
      <BBHead clientKey={clientKey} seo={seo} />
      <body>
        <BBBodyStart clientKey={clientKey} />
        {children}
        <BBBodyEnd clientKey={clientKey} />
      </body>
    </>
  );
};

export default BBMainLayout;
