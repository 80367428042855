import React, { useState, useRef, useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./index.css";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import BusinessServiceCard from "../BusinessServiceCard";
import { dataACC, dataET, dataFB, dataRT, responsive } from "./data";
import { useTranslation } from "react-i18next";

const ServicesCarousel = ({ type }) => {
  const { t } = useTranslation();
  const carouselRef = useRef(null);
  const [curSlide, setCurSlide] = useState(0);

  const data = useMemo(() => {
    if (type === "entertainment_venues") {
      return dataET(t);
    } else if (type === "food") {
      return dataFB(t);
    } else if (type === "accommodation") {
      return dataACC(t);
    } else if (type === "retail") {
      return dataRT(t);
    }
  }, [type, t]);

  const handleNextSlide = () => {
    const nextSlide = curSlide + 1;
    if (nextSlide < data.length) {
      carouselRef.current?.next();
      setCurSlide(nextSlide);
    }
  };

  const handlePrevSlide = () => {
    if (curSlide > 0) {
      carouselRef.current?.previous();
      setCurSlide(curSlide - 1);
    }
  };

  return (
    <div className="flex flex-col justify-center w-full">
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        infinite={false}
        autoPlay={false}
        showDots={false}
        partialVisible={false}
        arrows={false}
        swipeable={false}
        draggable={false}
        keyBoardControl={false}
        containerClass={"pt-6  services-carousel w-full"}
      >
        {data.map((item, index) => (
          <div
            onClick={() => {}}
            className="flex flex-col align-middle "
            key={index}
          >
            <BusinessServiceCard type={type} data={item[0]} />
            <BusinessServiceCard type={type} data={item[1]} />
          </div>
        ))}
      </Carousel>
      <div className="flex flex-row justify-center items-center">
        <div
          className="flex flex-row justify-center items-center cursor-pointer px-3 py-3  rounded-full"
          onClick={handlePrevSlide}
        >
          <BsArrowLeft size={18} color={curSlide > 0 ? "#5C1C81" : "#C0C0C0"} />
        </div>
        <div className="w-[16px]" />
        <div
          className="flex flex-row justify-center items-center cursor-pointer px-3 py-3  rounded-full"
          onClick={handleNextSlide}
        >
          <BsArrowRight
            size={18}
            color={curSlide < data.length - 1 ? "#5C1C81" : "#C0C0C0"}
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesCarousel;
