import React from "react";
import useSeoData from "../../services/hooks/use-seo";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import NewsletterSubscription from "../../containers/footer/newsletter-subscription";
import { getBrandTranslation } from "./brands/brands";

const Newsletter = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getBrandTranslation("breadcrumb")} Newsletter
          </p>
        </div>

        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <NewsletterSubscription />
        </div>
      </div>
    </BBMainLayout>
  );
};

export default Newsletter;
