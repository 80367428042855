import React from "react";
import { getFooterTranslation } from "./BBFooter";

const NewsletterSubscription = () => {
  return (
    <div className="md:col-span-5 flex flex-col justify-start items-center md:items-start w-full">
      <h3 className="h3-title">{getFooterTranslation("register")}</h3>
      <p className="mt-7 w-full text-[15px] leading-[17px] text-[#000] flex items-center gap-1">
        <span>Email</span>
        <span className="text-[#e85c41] text-[150%]">*</span>
      </p>
      <input className="mt-1 w-full h-[34px] border border-[#ABB0B2]" />
      <button
        className="bg-[#000] mt-4 rounded-[4px] w-full md:w-auto h-[42px] px-5 text-[14px] text-white uppercase"
        onClick={() => {}}
      >
        {getFooterTranslation("signup")}
      </button>
    </div>
  );
};

export default NewsletterSubscription;
