import React from "react";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import { bybestBrands } from "./data";
import { getBrandTranslation } from "../brands/brands";

const ByBestLive = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase">
            {getBrandTranslation("breadcrumb")} Live
          </p>
        </div>
        <div className="w-full max-w-screen-main flex flex-col items-center gap-5 justify-center mt-7 mb-5">
          <div className="w-full text-center flex justify-center">
            <video className="w-[400px] h-[300px]" autoPlay controls>
              <source
                src="https://admin.bybest.shop/storage/upploads/by_best_image_0_1708104125.mp4"
                type="video/mp4"
              />
              <source
                src="https://admin.bybest.shop/storage/upploads/by_best_image_0_1708104125.mp4"
                type="video/ogg"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="grid grid-cols-4 gap-5 items-center w-11/12">
            {bybestBrands.map((brand, index) => (
              <a
                key={index}
                rel="noreferrer"
                href={brand.href}
                target="_blank"
                className="inline-block no-underline"
              >
                <img
                  src={brand.src}
                  alt={brand.alt}
                  className={`max-h-[${brand.maxHeight}] w-full max-w-full object-contain`}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestLive;
