import React, { useState } from "react";
import "../index.css";
import { paymentOptions } from "../data";
import CreditCardForm from "./credit-card-form";

const PaymentMethod = ({ setActiveStep }) => {
  const [paymentMethod, setPaymentMethod] = useState("Cash");

  return (
    <div className="col-span-2 flex flex-col gap-4 mt-12">
      <h1 className="payment-title text-xl md:text-2xl lg:text-3xl">
        Select a payment method
      </h1>
      <div className="flex justify-start gap-4">
        {paymentOptions.map((item, index) => {
          return (
            <div
              key={index}
              className={`flex justify-center items-center px-2 h-[59px] py-3 rounded-[10px] border border-[#000000] cursor-pointer ${
                item.value === paymentMethod ? "bg-[#F2F2F2]" : ""
              }`}
              onClick={() => setPaymentMethod(item.value)}
            >
              <span className="mr-2">{item.icon}</span>
              <span className="payment-label !text-14 lg:!text-16">
                {item.label}
              </span>
            </div>
          );
        })}
      </div>
      <CreditCardForm setActiveStep={setActiveStep} />
    </div>
  );
};

export default PaymentMethod;
