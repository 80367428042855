import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import BBMainLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../../services/hooks/use-seo";
import "./index.css";
import { useLocalStorage } from "../../../services/hooks/use-localstorage";
import { t } from "i18next";
import { getBrandTranslation } from "../brands/brands";

const getWishlistText = (label = "") => t(`bybest.wishlist.${label}`);

const ByBestWishList = () => {
  const { pageSEO } = useSeoData();
  const [productList, setProductList] = useLocalStorage("wishlist", []);

  const [showMessage, setShowMessage] = useState(null);

  const onRemoveItem = (product, type = null) => {
    setProductList(productList?.filter((item) => item.id !== product.id));
    setShowMessage(type);
  };

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white overflow-hidden">
        {showMessage !== null && (
          <div className="success-message w-full">
            <span>{getWishlistText(showMessage)}</span>
          </div>
        )}
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] breadcumb">
          {getBrandTranslation("breadcrumb")} {getWishlistText("wishlist")}
        </div>

        <div className="w-full max-w-screen-main mt-7 mb-5 px-5 md:px-0">
          <div className="mt-5 w-full px-[15px] py-[10px] rounded-[5px] text-[14px] text-white bg-black uppercase">
            {getBrandTranslation("products")}
          </div>
          <div className="mt-5 w-full">
            {productList.map((product) => (
              <div className="relative w-full mt-3 pb-3 grid grid-cols-3 md:flex md:justify-center items-center border-b border-b-[#d2d2d2] !bg-white">
                <div className="h-[220px] image-details">
                  <img
                    src={product.img1}
                    className="w-fit h-full object-contain"
                    alt=""
                  />
                </div>
                <div className="col-span-2 h-[220px] ml-4 flex flex-col justify-between items-start w-full overflow-hidden">
                  <div className="flex justify-between w-full pr-2 gap-2 overflow-hidden">
                    <div className="flex flex-col overflow-hidden">
                      <span className="product-title truncate">
                        {product.title}
                      </span>
                      <span className="product-description truncate">
                        {product.category}
                      </span>
                    </div>

                    <div
                      className="w-[38px] h-[38px] rounded border border-[#222] flex justify-center items-center cursor-pointer mr-2 md:mr-0"
                      onClick={() => onRemoveItem(product, "removeItem")}
                    >
                      <MdClose color="#222" size={20} />
                    </div>
                  </div>

                  <div className="w-full grid xl:flex justify-between items-end">
                    <p className="text-[13px] text-black font-medium">
                      {getBrandTranslation("price")}:{" "}
                      <b>LEK {product.sale_price ?? "150"}</b>
                    </p>
                    <div
                      className="flex lg:justify-end"
                      onClick={() => setShowMessage("addedCart")}
                    >
                      <button className="w-full mt-2 rounded-[5px] bg-black h-[42px] font-bold text-[14px] text-white uppercase">
                        {getBrandTranslation("addtobag")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestWishList;
