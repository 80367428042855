import React from "react";
import { getTranslation } from "./landing";
import { brandShowcase } from "./data";
import "./landing.css";
import { useNavigate, useParams } from "react-router-dom";

const NewBrandsSection = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8">
      <div className="lg:flex justify-center mb-8 gap-1">
        <span className="bybest-title-size">
          {getTranslation("brandShowcaseTitle")}
        </span>{" "}
        {/*<strong className="bybest-title-size">*/}
        {/*  {getTranslation("iconbrands")}*/}
        {/*</strong>*/}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
        {brandShowcase.map((brand, index) => (
          <div key={index} className="flex flex-col gap-1 items-center">
            <div className="flex flex-col gap-10">
              <img
                src={brand.logo}
                alt={`${brand.name} logo`}
                className="h-16 mb-0 object-contain"
              />
              <img
                src={brand.image}
                alt={brand.name}
                className="w-full h-64 object-cover mb-4 rounded-lg"
              />
            </div>
            <span className="brand-image-text">{brand.description}</span>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <div>
          <button className="flex justify-center" onClick={() => navigate(``)}>
            {getTranslation("discoverCollectionsButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewBrandsSection;
