import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { NotificationManager } from "react-notifications";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import WhiteLabelService from "../../services/whiteLabelService";

const validator = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
});

const ByBestLogin = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const formikProps = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validator,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const vbToken = localStorage.getItem("vbToken");

  useEffect(() => {
    if (vbToken && app_key) {
      navigate("/customer/orders");
    }
  }, [vbToken, app_key]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.clear();
  }, []);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleChange = (e) => {
    formikProps.setFieldValue([e.target.name], e.target.value);
  };

  const handleLogin = () => {
    const payload = { ...formikProps.values, source: "bybest.shop_web" };
    WhiteLabelService.bybestLogin(payload, app_key)
      .then(() => {
        NotificationManager.success(
          "You've been logged in successfully",
          "Success",
          3000
        );

        localStorage.setItem("vbToken");

        navigate(`/customer/orders`);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || "Something went wrong!",
          "Error",
          3000
        );
      });
  };

  const onRegister = () => {
    navigate("/register");
  };

  return (
    <BBMainLayout clientKey="bybest">
      <div className="w-full py-5 md:py-20 px-4 flex flex-col align-col-middle bg-[#DEE2E6]">
        <div className="p-6 w-full md:w-[440px] rounded-[4px] border bg-white border-[#00000020]">
          <h1 className="text-[16px] font-bold leading-[24px] text-[#000]">
            Hyr
          </h1>
          <p className="text-[12px] leading-[18px] text-[#000]">
            Hyni ne llogarine tuaj
          </p>
          <div className="mt-3">
            <p className="text-[12px] leading-[18px] text-[#000]">Email</p>
            <input
              name="email"
              type="email"
              value={formikProps.values.email}
              error={formikProps.errors.email}
              onChange={handleChange}
              className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
            />
            <p className="mt-3 text-[12px] leading-[18px] text-[#000]">
              Fjalekalimi
            </p>
            <div className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400 ">
              <input
                type={type}
                name="password"
                value={formikProps.values.password}
                error={formikProps.errors.password}
                onChange={handleChange}
                autoComplete="current-password"
                className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
              />
              <span
                className="flex justify-around items-center"
                style={{ color: "#CBD5E1" }}
                onClick={handleToggle}
              >
                <Icon class="absolute " icon={icon} size={18} />
              </span>
            </div>
            <div className="w-full flex justify-end items-end mt-3">
              <div className="text-[#000] text-[14px] leading-[21px] underline cursor-pointer">
                Keni harruar fjalekalimin?
              </div>
            </div>

            <button
              className="mt-5  text-white text-[14px] leading-[21px] font-medium bg-[#000]  rounded-[4px] h-[41px] w-full"
              onClick={handleLogin}
            >
              Hyr
            </button>
            <div className="mt-4 w-full flex justify-center items-center ">
              <p className="text-[#000] font-medium text-[14px] leading-[21px]">
                Nuk keni nje llogari?
              </p>
              <button
                className="px-2 h-[24px] text-[#000] font-medium text-[14px] leading-[21px] underline"
                onClick={onRegister}
              >
                Regjistrohu
              </button>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestLogin;
