export const data = (getProductsText) => [
  {
    label: getProductsText('details'),
    content: (
      <div class="w-full ">
        <p className="text-[13px] text-[#000] font-semibold">
          Mjete të vogla dhe të dobishme
          <br />
        </p>
        <ul className="mt-2 px-4 list-disc">
          <li className="mt-1 text-[13px] text-[#000]">
            Ngjyra:Rozë ose Jeshile
          </li>
          <li className="mt-1 text-[13px] text-[#000]">
            Materiali:SS + Silikoni + PP + TPR
          </li>
          <li className="mt-1 text-[13px] text-[#000]">Masa:27x7x7</li>
        </ul>
      </div>
    ),
  },
  {
    label: getProductsText('moreInfo'),
    content: (
      <div class="w-full ">
        <p className="text-[13px] text-[#000]">
          <b>Mopita</b>
        </p>
        <p className="text-[13px] text-[#000]">
          Mopita është një kompani italiane e specializuar në prodhimin dhe
          tregtimin e eneve te gatimit. E gjithë kjo falë kreativitetit,
          fleksibilitetit dhe inovacionit.
        </p>
        <p className="text-[13px] text-[#000]">
          Prodhimi i produkteve të kuzhinës me dizajn origjinal, rezistent dhe
          cilësor, kërkon profesionalizëm të madh, jo vetëm të natyrës teknike,
          por edhe artistike. Për ne, shija e mirë estetike italiane, intuita
          kreative dhe funksionale, vëmendja ndaj detajeve dhe përzgjedhja e
          materialeve janë elemente thelbësore për të kënaqur klientët tanë.
          Jemi të vetëdijshëm se kemi përgjegjësinë e madhe për të hyrë në
          kuzhinat e botës, ndaj e bëjmë këtë me produkte të cilësisë së lartë
          që nuk çlirojnë ndotje dhe nuk shkaktojnë ndryshime në ushqim. Qëllimi
          është t'u japim adhuruesve të kuzhinës produkte me një profil të lartë
          estetik dhe efikasitet të padiskutueshëm, pa neglizhuar asnjëherë
          rëndësinë e qëndrueshmërisë mjedisore që ne ndjekim me vendosmëri.
          Qëllimi ynë është të krijojmë një markë ku bukuria është një vlerë e
          arritshme.&nbsp;Mopita ju ofrom mjetet, ju kujdeseni per shijen.
        </p>
        <p className="text-[13px] text-[#000]">Misioni</p>
        <p className="text-[13px] text-[#000]">
          1. Ofron një gamë të gjerë produktesh cilësore me çmime të arsyeshme.
          <br />
          <span>
            2. Projektimi, zhvillimi dhe prodhimi i enëve të kuzhinës, me një
            dizajn origjinal, duke përdorur materiale të cilësisë së lartë që
            nuk çlirojnë ndotje ose nuk shkaktojnë ndryshime.
            <br />
          </span>
          <span>
            3. Krijimi i produkteve që respektojnë mjedisin nëpërmjet
            teknologjive që optimizojnë konsumin e energjisë.
            <br />
          </span>
          <span>
            4. Promovoni njohuritë e Mopita në mbarë botën, të krijuara mbi
            tridhjetë vjet përvojë në këtë sektor.
            <br />
          </span>
          <span>
            5. Mbështetja e procesit të rivlerësimit të prodhimit artizanal dhe
            industrial italian, i cili ndër vite ka bërë që Made in Italy të
            shkëlqejë në konkurrencën tregtare.
          </span>
        </p>
      </div>
    ),
  },
  {
    label: getProductsText('bbMembers'),
    content: (
      <div class="w-full ">
        <p className="text-[13px] text-[#000]">
          <b>KARTË KLIENTI “BY BEST GROUP”</b>
        </p>
        <p className="text-[13px] text-[#000]">
          <b>
            Çfarë është karta “By Best Group”
            <br />
          </b>
          <span>
            Është një program i cili ju mundëson të fitoni pikë me blerjet tuaja
            në 4 dyqanet tona dhe t’i shpenzoni ato në secilën prej tyre
            nëpërmjet një karte të vetme. Karta “By Best Group” përdoret vetëm
            në rrjetin e dyqaneve të kompanisë “By Best Duty Free”. Nëpërmjet
            kartës “By Best Group” ju përfitoni ulje, dhurata, promocione e
            aktivitete të dedikuara vetëm për ju në dyqanet: SWAROVSKI, SWATCH,
            BLUKIDS, VILLEROY &amp; BOCH.
          </span>
        </p>
        <p className="text-[13px] text-[#000]">
          <b>
            Si funksionon karta “By Best Group”
            <br />
          </b>
          <span>
            Karta “By Best Group” nuk është e transferueshme tek persona të
            tjerë të padeklaruar në formularin e antarësimit.
          </span>
        </p>
        <p className="text-[13px] text-[#000]">
          <b>
            Si ta përdorni kartën “By Best Group”
            <br />
          </b>
          <span>
            Tregoni kartën “By Best Group” çdo herë që bëni blerje në dyqanet
            partnere.&nbsp;
          </span>
          <span>
            Mblidhni pikë në secilën prej këtyre blerjeve duke filluar nga 100
            lekë. Për çdo 100 lekë të shpenzuara ju do të fitoni 1 pikë. Pikët e
            grumbulluara do të shfaqen në faturën e blerjes.&nbsp;
          </span>
          <span>
            Bëhuni pjesë e klubeve të përfitimeve duke filluar nga 150 pikë. Për
            çdo 150, 250, 500, e deri 1.000 pikë, ju keni të drejtë të kërkoni
            një kupon në kasë, me të cilin do të përfitoni, uljen apo dhuratat.
          </span>
        </p>
        <p className="text-[13px] text-[#000]">
          <span>
            Në rast se ju harroni të përdorni kartën në blerjet tuaja, pikët nuk
            mund t’ju kalojnë manualisht nga kasieri në kartë. Karta është e
            detyrueshme të përdoret si në blerje ashtu edhe në shkarkimin e
            pikëve. Pa kartën prezente nuk mund të kryhet asnje veprim në kasë.
          </span>
          <br />
        </p>
        <p className="text-[13px] text-[#000]">
          <span>
            Në rastet kur sistemi mund të jetë offline nuk humbisni asnjë pike.
            Ato do të kalohen në kartë në momentin që do të realizohet lidhja. E
            njëjta gjë ndodh edhe kur ju do të doni të shkarkoni pikët tuaja
            ndërkohë që sistemi është offline. Ju do të mund të përfitoni
            kuponin në një moment të dytë dhe ta përdorni atë në blerjet tuaja.
          </span>
          <br />
        </p>
        <p className="text-[13px] text-[#000]">
          <span>
            Karta “By Best Group” nuk ështe dhe nuk vlen si një kartë krediti.
            Me të nuk mund të kryhen veprime monetare, por vetëm të grumbullohen
            pikë e të përfitohen privilegjet e përcaktuara për pjestarët e
            skemës.
          </span>
          <br />
        </p>
      </div>
    ),
  },
];
