import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BBLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import ImgBrand1 from "../../../assets/images/bybest/brands/brand1.png";
import ImgBrand2 from "../../../assets/images/bybest/brands/brand2.png";
import ImgBrand3 from "../../../assets/images/bybest/brands/brand3.png";
import ImgBrand4 from "../../../assets/images/bybest/brands/brand4.png";
import ImgBrand5 from "../../../assets/images/bybest/brands/brand5.png";
import ImgBrand6 from "../../../assets/images/bybest/brands/brand6.png";
import ImgBrand7 from "../../../assets/images/bybest/brands/brand7.png";
import ImgBrand8 from "../../../assets/images/bybest/brands/brand8.png";
import ImgBrand9 from "../../../assets/images/bybest/brands/brand9.png";
import BybestHomeCarousel from "../../../components/BybestHomeCarousel";
import { brandHeaderList, home_products } from "../data";
import BybestProductItem from "../../../components/BybestProductItem";
import BybestHomeNotiModal from "../../../components/BybestHomeNotiModal";
import useSeoData from "../../../services/hooks/use-seo";
import ByBestHomeMobile from "./mobile-home";
import { useLocalStorage } from "../../../services/hooks/use-localstorage";

export const brands = [
  ImgBrand1,
  ImgBrand2,
  ImgBrand3,
  ImgBrand4,
  ImgBrand5,
  ImgBrand6,
  ImgBrand7,
  ImgBrand8,
  ImgBrand9,
];

const ByBestHome = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState(home_products);

  const [productList, setProductList] = useLocalStorage("wishlist", []);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, [30 * 1000]);
  }, []);

  useEffect(() => {
    const updatedProducts = home_products.map((item) => ({
      ...item,
      products: item.products.map((p) => ({
        ...p,
        favorite: productList.some((favItem) => favItem.id === p.id),
      })),
    }));

    setProducts(updatedProducts);
  }, [productList]);

  const onGoProductDetails = (p) => {
    navigate(`/products/${p.id}/akeasf`);
  };

  const onFavoriteChange = (product) => {
    if (productList?.some((item) => item.id === product.id)) {
      setProductList(productList?.filter((item) => item.id !== product.id));
      setProducts(
        products.map((item) => ({
          ...item,
          products: item.products.map((p) =>
            p.id === product.id ? { ...p, favorite: false } : p
          ),
        }))
      );
    } else {
      setProductList([
        ...productList,
        { ...product, sale_price: product.price },
      ]);
      setProducts(
        products.map((item) => ({
          ...item,
          products: item.products.map((p) =>
            p.id === product.id ? { ...p, favorite: true } : p
          ),
        }))
      );
    }
  };

  const { pageSEO } = useSeoData();

  return (
    <BBLayout clientKey="bybest" seo={pageSEO}>
      <div className="md:hidden">
        <ByBestHomeMobile />
      </div>
      <div className="hidden md:block w-full bg-white">
        <div className="w-full bg-[#3B3B3A] py-1">
          <div className="max-w-screen-main mx-auto">
            <div className="icons">
              {brandHeaderList.map((item, index) => (
                <div
                  onClick={() => navigate(`/brands/${item.url}`)}
                  key={index}
                  className="cursor-pointer"
                >
                  <img src={item.src} alt={item.name} className="brand-icon" />
                </div>
              ))}
            </div>
          </div>
        </div>

        <BybestHomeCarousel />
        <div className="w-full px-4 sm:px-6 md:px-8 lg:px-[10%] md:mt-16 mb-5">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-3 px-5 md:px-0">
            {products.map((p, index) => (
              <React.Fragment key={index}>
                <div className="mb-0 md:mb-20 bg-[#f9f9f9] rounded-[5px]">
                  <img
                    alt=""
                    src={p.cat_img}
                    className="w-full h-[120px] md:h-auto object-contain rounded-[5px]"
                  />
                </div>
                <div className="mb-0 md:mb-20 col-span-3 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3">
                  {p.products.map((item, index) => (
                    <BybestProductItem
                      key={index}
                      data={item}
                      type="home"
                      onClick={() => onGoProductDetails(item)}
                      onFavoriteChange={() => onFavoriteChange(item)}
                      productList={productList}
                    />
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        {showModal && (
          <BybestHomeNotiModal
            showModal={showModal}
            onClose={() => {
              setShowModal(false);
            }}
          />
        )}
      </div>
    </BBLayout>
  );
};

export default ByBestHome;
