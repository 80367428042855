import React from "react";
import { getTranslation } from "./landing";
import { reviews } from "./data";
import "./landing.css";

const ReviewSection = () => {
  return (
    <div className="review-section w-full">
      <div className="flex justify-center w-full">
        <div className="lg:flex items-center gap-1">
          {/*<span className="bybest-title-reviews">*/}
          {/*  {getTranslation("experiences")}*/}
          {/*</span>{" "}*/}
          <strong className="bybest-title-reviews">
            {getTranslation("reviewsTitle")}
          </strong>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        {reviews.map((review, index) => (
          <div key={index} className="block">
            <span className="text">{review.text}</span>
            <div className="author-section">
              <p className="author">{review.author}</p>
              <p className="author-role">{review.title}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="lg:flex justify-center items-center flex-col gap-3 mb-4 md:mt-8 w-full">
        <h2 className="text-center lg:text-xl font-bold">
          {getTranslation("averageRating")}
        </h2>
          <div className="lg:flex text-center justify-center mt-2 lg:space-x-2">
          <span className="text-black font-bold cursor-pointer">
            {getTranslation("readMoreStoriesButton")}
          </span>
              <span className="text-black font-semibold ml-2 lg:ml-0">
            {getTranslation("or")}
          </span>
              <a
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/ANUSADUfq3B9Dp267?g_st=com.google.maps.preview.copy"
                  target="_blank"
                  className=""
              >
                  <div className="text-[#cb0000] font-bold underline cursor-pointer">
                      {getTranslation("shareExperienceButton")}
                  </div>
              </a>
          </div>
      </div>
    </div>
);
};

export default ReviewSection;
