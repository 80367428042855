import React from "react";
import "./index.css";

const ProductChildrenOptions = () => {
  const options = [
    { id: 582, label: "9-12 Muajsh", disabled: true },
    { id: 558, label: "12-18 Muajsh", disabled: false },
    { id: 561, label: "18-24 Muajsh", disabled: true },
    { id: 563, label: "24-30 Muajsh", disabled: false },
    { id: 568, label: "30-36 Muajsh", disabled: false },
  ];

  const handleOptionClick = (variantId) => {
    // Logic for handling the click can go here
    console.log(`Selected option with ID: ${variantId}`);
  };

  const spinnerStyle = {
    marginRight: "10px",
    display: "none",
  };

  return (
    <div className="options" id="attribute_container">
      <div className="option-category">
        <div>Masa</div>
      </div>

      <div className="option-boxes">
        <input
          type="hidden"
          id="selected_option"
          name="options_selected[]"
          value=""
        />

        {options.map((option) => (
          <button
            key={option.id}
            className={`btn btn-primary option-box ${
              option.disabled ? "disabled" : ""
            }`}
            id={`option-box-${option.id}`}
            onClick={() => !option.disabled && handleOptionClick(option.id)}
            type="button"
            disabled={option.disabled}
          >
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              style={spinnerStyle}
            />
            <span>{option.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProductChildrenOptions;
