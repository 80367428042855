import React from "react";
import { Helmet } from "react-helmet-async";
import { getWhitelabelSeoClientConfig } from "../../../../utils/whitelabelSeoConfigs";

const BBHead = ({ clientKey = "bybest", seo = {} }) => {
  const clientConfig = getWhitelabelSeoClientConfig(clientKey);
  const bbSEO = clientConfig.seo;

  const mergedSEO = { ...bbSEO, ...seo };

  // Dynamically import the favicon
  const faviconUrl = require(`../../../../assets/whitelabel/${bbSEO.favicon}`);
  const icoURL = require(`../../../../assets/whitelabel/${bbSEO.ico}`);

  return (
    <Helmet prioritizeSeoTags>
      <html lang={clientConfig.defaultLanguage || mergedSEO.lang} />
      <link rel="icon" href={icoURL} />
      <title>{bbSEO.defaultTitle || mergedSEO.title}</title>
      <meta
        name="description"
        content={bbSEO.defaultDescription || mergedSEO.description}
      />
      <meta
        name="keywords"
        content={bbSEO.defaultKeywords || mergedSEO.keywords}
      />

      {/* Open Graph */}
      <meta
        property="og:title"
        content={bbSEO.defaultTitle || mergedSEO.title}
      />
      <meta
        property="og:description"
        content={bbSEO.defaultDescription || mergedSEO.description}
      />
      <meta
        property="og:image"
        content={bbSEO.defaultOgImage || mergedSEO.ogImage}
      />
      <meta
        property="og:url"
        content={`https://${clientConfig.domain}` || mergedSEO.ogUrl}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={clientConfig.name} />
      <meta
        property="og:locale"
        content={clientConfig.defaultLanguage || "en_US"}
      />
      <meta
        property="og:image:secure_url"
        content={bbSEO.defaultOgImage || mergedSEO.ogImage}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <meta
        property="og:image:alt"
        content={`${clientConfig.name} - Shopping with Style & Quality`}
      />

      <link
        rel="canonical"
        href={`https://${clientConfig.domain}` || mergedSEO.ogUrl}
      />

      {/* Favicon */}
      <link rel="icon" href={faviconUrl} />
      <link rel="shortcut icon" href={faviconUrl} />
      <link rel="apple-touch-icon" href={faviconUrl} />

      {/* Google Tag Manager */}
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${clientConfig.analytics.googleTagManager}');`}
      </script>

      {/* Facebook Pixel */}
      <script>
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${clientConfig.analytics.facebookPixel}');
        fbq('track', 'PageView');
        `}
      </script>
      <noscript>
        {`<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${clientConfig.analytics.facebookPixel}&ev=PageView&noscript=1"
        />`}
      </noscript>

      {/* TikTok Pixel */}
      <script>
        {`
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0;n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
          ttq.load('${clientConfig.analytics.tiktokPixel}');
          ttq.page();
        }(window, document, 'ttq');
        `}
      </script>

      {/* Tawk.to Chat */}
      <script>
        {`
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/${clientConfig.chat.tawkTo}';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
        `}
      </script>
    </Helmet>
  );
};

export default BBHead;
