import { t } from "i18next";
import Swarovski from "../../../assets/images/landing/swarovski.png";
import Swatch from "../../../assets/images/landing/swatch.png";
import Villeroy from "../../../assets/images/landing/villeroy.png";
import Blukids from "../../../assets/images/landing/blukids.png";

const getTranslation = (label = "") => t(`bybest.landing.${label}`);

export const brands = [
  { value: "", title: getTranslation("pleaseSelect") },
  { value: 5, title: "Swarovski" },
  { value: 3, title: "Swatch" },
  { value: 6, title: "Villeroy & Boch" },
  { value: 4, title: "Blukids" },
];

export const benefits = [
  {
    id: 1,
    img: "https://bybest.shop/assets/img/discount.svg",
    title: getTranslation("benefits.earnPoints.title"),
    subtitle: getTranslation("benefits.earnPoints.subtitle"),
  },
  {
    id: 2,
    img: "https://bybest.shop/assets/img/gift.svg",
    title: getTranslation("benefits.exclusiveOffers.title"),
    subtitle: getTranslation("benefits.exclusiveOffers.subtitle"),
  },
  {
    id: 3,
    img: "https://bybest.shop/assets/img/points.svg",
    title: getTranslation("benefits.vipEvents.title"),
    subtitle: getTranslation("benefits.vipEvents.subtitle"),
  },
  {
    id: 4,
    img: "https://bybest.shop/assets/img/star.svg",
    title: getTranslation("benefits.freeShipping.title"),
    subtitle: getTranslation("benefits.freeShipping.subtitle"),
  },
  {
    id: 5,
    img: "https://bybest.shop/assets/img/heart.svg",
    title: getTranslation("benefits.personalizedService.title"),
    subtitle: getTranslation("benefits.personalizedService.subtitle"),
  },
];

export const brandShowcase = [
  {
    name: "Swarovski",
    logo: "https://admin.bybest.shop/storage/brands/brands_colored_1654870085.png",
    image: Swarovski,
    description: getTranslation("brandShowcase.swarovski.description"),
  },
  {
    name: "Swatch",
    logo: "https://admin.bybest.shop/storage/brands/brands_colored_1644772840.png",
    image: Swatch,
    description: getTranslation("brandShowcase.swatch.description"),
  },
  {
    name: "Villeroy & Boch",
    logo: "https://admin.bybest.shop/storage/brands/brands_colored_1650011150.png",
    image: Villeroy,
    description: getTranslation("brandShowcase.villeroyBoch.description"),
  },
  {
    name: "Blukids",
    logo: "https://admin.bybest.shop/storage/brands/brands_colored_1657033286.png",
    image: Blukids,
    description: getTranslation("brandShowcase.blukids.description"),
  },
];

export const loyaltyLevels = [
  {
    name: getTranslation("loyaltyLevels.bronze.name"),
    description: getTranslation("loyaltyLevels.bronze.description"),
    benefits: getTranslation("loyaltyLevels.bronze.benefits"),
  },
  {
    name: getTranslation("loyaltyLevels.silver.name"),
    description: getTranslation("loyaltyLevels.silver.description"),
    benefits: getTranslation("loyaltyLevels.silver.benefits"),
  },
  {
    name: getTranslation("loyaltyLevels.gold.name"),
    description: getTranslation("loyaltyLevels.gold.description"),
    benefits: getTranslation("loyaltyLevels.gold.benefits"),
  },
  {
    name: getTranslation("loyaltyLevels.platinum.name"),
    description: getTranslation("loyaltyLevels.platinum.description"),
    benefits: getTranslation("loyaltyLevels.platinum.benefits"),
  },
];

export const reviews = [
  {
    text: getTranslation("reviews.review1.text"),
    author: getTranslation("reviews.review1.author"),
    title: getTranslation("reviews.review1.title"),
  },
  {
    text: getTranslation("reviews.review2.text"),
    author: getTranslation("reviews.review2.author"),
    title: getTranslation("reviews.review2.title"),
  },
  {
    text: getTranslation("reviews.review3.text"),
    author: getTranslation("reviews.review3.author"),
    title: getTranslation("reviews.review3.title"),
  },
  {
    text: getTranslation("reviews.review4.text"),
    author: getTranslation("reviews.review4.author"),
    title: getTranslation("reviews.review4.title"),
  },
  {
    text: getTranslation("reviews.review5.text"),
    author: getTranslation("reviews.review5.author"),
    title: getTranslation("reviews.review5.title"),
  },
  {
    text: getTranslation("reviews.review6.text"),
    author: getTranslation("reviews.review6.author"),
    title: getTranslation("reviews.review6.title"),
  },
];

export const faqs = [
  {
    question: getTranslation("faqs.faq1.question"),
    answer: getTranslation("faqs.faq1.answer"),
  },
  {
    question: getTranslation("faqs.faq2.question"),
    answer: getTranslation("faqs.faq2.answer"),
  },
  {
    question: getTranslation("faqs.faq3.question"),
    answer: getTranslation("faqs.faq3.answer"),
  },
  {
    question: getTranslation("faqs.faq4.question"),
    answer: getTranslation("faqs.faq4.answer"),
  },
  // {
  //   question: getTranslation("faqs.faq1.question"),
  //   answer: getTranslation("faqs.faq1.answer"),
  // },
  // {
  //   question: getTranslation("faqs.faq2.question"),
  //   answer: getTranslation("faqs.faq2.answer"),
  // },
  // {
  //   question: getTranslation("faqs.faq3.question"),
  //   answer: getTranslation("faqs.faq3.answer"),
  // },
  // {
  //   question: getTranslation("faqs.faq4.question"),
  //   answer: getTranslation("faqs.faq4.answer"),
  // },
];

export const checkboxIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.837891 9.28601C0.837891 4.36945 4.82355 0.383789 9.74011 0.383789H12.8143C17.7308 0.383789 21.7165 4.36945 21.7165 9.28601V12.3602C21.7165 17.2767 17.7308 21.2624 12.8143 21.2624H9.74011C4.82355 21.2624 0.837891 17.2767 0.837891 12.3602V9.28601Z"
      fill="#CB0000"
    />
    <g clipPath="url(#clip0_1648_7489)">
      <g filter="url(#filter0_d_1648_7489)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9626 5.16538C16.4447 4.6297 17.2698 4.58628 17.8054 5.06839C18.3411 5.5505 18.3846 6.37558 17.9024 6.91126L10.073 15.6107C9.57283 16.1664 8.70898 16.1891 8.18032 15.6604L4.70056 12.1807C4.19096 11.6711 4.19096 10.8449 4.70056 10.3353C5.21016 9.82566 6.03638 9.82566 6.54598 10.3353L9.05319 12.8425L15.9626 5.16538Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1648_7489"
        x="4.31836"
        y="4.7334"
        width="13.9199"
        height="11.9615"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.652456" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1648_7489"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1648_7489"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1648_7489">
        <rect
          width="13.9191"
          height="11.3092"
          fill="white"
          transform="translate(4.31836 4.7334)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const plusIcon = (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8.03467C18.6443 8.03467 19.1667 8.557 19.1667 9.20133V17.368H27.3333C27.9777 17.368 28.5 17.8903 28.5 18.5347C28.5 19.179 27.9777 19.7013 27.3333 19.7013H19.1667V27.868C19.1667 28.5123 18.6443 29.0347 18 29.0347C17.3557 29.0347 16.8333 28.5123 16.8333 27.868V19.7013H8.66667C8.02233 19.7013 7.5 19.179 7.5 18.5347C7.5 17.8903 8.02233 17.368 8.66667 17.368L16.8333 17.368V9.20133C16.8333 8.557 17.3557 8.03467 18 8.03467Z"
      fill="#CB0000"
    />
  </svg>
);

export const arrowUpIcon = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 13.8828L10 8.88281L5 13.8828"
      stroke="#737373"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const arrowDownIcon = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8.88281L10 13.8828L5 8.88281"
      stroke="#737373"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
