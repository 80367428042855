import React, { useState } from "react";
import "./index.css";
import videoImg from "../../../assets/images/eight-features-video.webp";
import { useNavigate } from "react-router-dom";
import { getGrowTranslation, list } from "./data";

const Grow = () => {
  const [selected, setSelected] = useState(list()[0]);

  const [iframeSrcMobile, setIframeSrcMobile] = useState("");
  const [iframeSrcWeb, setIframeSrcWeb] = useState("");

  const handleImageClickMobile = () => {
    if (selected.title === "Promotions That Hit") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/HZ0K63DQi74SBypcLX8Hq?autoplay=1"
      );
    }

    if (selected.title === "Loyalty: Naturally Built") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/tWGb_CsVoBE9IhrIYFsVr?autoplay=1"
      );
    }

    if (selected.title === "Inventory: Always Right") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/GAdlAqkoed-4R_Vp1XLGB?autoplay=1"
      );
    }

    if (selected.title === "Teams: Perfectly Tuned") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/OZvjEwZ3gfB3l46-zIENx?autoplay=1"
      );
    }

    if (selected.title === "Decisions: Data-Driven") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/clsymaye81fdzped7ddh1yrd5?autoplay=1"
      );
    }

    if (selected.title === "Payments: Click & Secure") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/yDAyoe_ulDWLOkUJNzw_Y?autoplay=1"
      );
    }

    if (selected.title === "Branding: Uniquely Yours") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/A0YF5_bdAkI27y-0i-S9i?autoplay=1"
      );
    }

    if (selected.title === "Bookings Made Easy") {
      setIframeSrcMobile(
        "https://app.supademo.com/embed/clsylfmmn1e8oped7f2atdru3?autoplay=1"
      );
    }
  };

  const handleImageClickWeb = () => {
    if (selected.title === "Promotions That Hit") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/HZ0K63DQi74SBypcLX8Hq?autoplay=1"
      );
    }

    if (selected.title === "Loyalty: Naturally Built") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/tWGb_CsVoBE9IhrIYFsVr?autoplay=1"
      );
    }

    if (selected.title === "Inventory: Always Right") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/GAdlAqkoed-4R_Vp1XLGB?autoplay=1"
      );
    }

    if (selected.title === "Teams: Perfectly Tuned") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/OZvjEwZ3gfB3l46-zIENx?autoplay=1"
      );
    }

    if (selected.title === "Decisions: Data-Driven") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/clsymaye81fdzped7ddh1yrd5?autoplay=1"
      );
    }

    if (selected.title === "Payments: Click & Secure") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/yDAyoe_ulDWLOkUJNzw_Y?autoplay=1"
      );
    }

    if (selected.title === "Branding: Uniquely Yours") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/A0YF5_bdAkI27y-0i-S9i?autoplay=1"
      );
    }

    if (selected.title === "Bookings Made Easy") {
      setIframeSrcWeb(
        "https://app.supademo.com/embed/clsylfmmn1e8oped7f2atdru3?autoplay=1"
      );
    }
  };

  const navigate = useNavigate();
  const navigateToUrl = (url) => {
    navigate(url);
  };

  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <div className="w-[100vw] relative bg-[#F3F3F3]">
        <div className="mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16">
          <div className="flex flex-col items-center py-10 gap-4">
            <span className="text-primary1 text-28 md:text-44 font-black text-center md:text-left">
              {getGrowTranslation("keyRevealed")}
            </span>
            <span className="text-sm md:text-xl text-gray4 text-semibold text-left md:text-center">
              {getGrowTranslation("explore")}
            </span>
            <div className="w-full flex flex-col md:flex-row justify-between mt-4 md:mt-12">
              {/*<div className='hidden md:flex flex-col gap-6 pr-[60px]'>*/}
              <div
                className="hidden md:flex flex-col gap-6 pr-[0px]"
                style={{ width: "80%" }}
              >
                {list().map((item, index) => (
                  <div
                    key={index}
                    className={`flex items-center px-8 rounded-lg cursor-pointer w-[400px] h-20 ${
                      item.title === selected.title
                        ? "bg-primary1 rounded-lg"
                        : "rounded-[20px] border-b border-b-[#240B3B] bg-white"
                    }
                    shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]
                    `}
                    onClick={() => {
                      setSelected(item);
                      setIframeSrcWeb("");
                    }}
                  >
                    <span
                      className={`text-xl font-bold ${
                        item.title === selected.title
                          ? "text-pink3"
                          : "text-primary1"
                      }`}
                    >
                      {item.title}
                    </span>
                  </div>
                ))}
              </div>

              <div className="flex flex-col md:hidden gap-3 border-primary1 w-full">
                {list().map((item, index) => (
                  <>
                    <div
                      key={index}
                      className={`secondBlock flex flex-row items-center justify-between px-7  cursor-pointer w-full h-[50px] ${
                        item.title === selected.title
                          ? "bg-primary1 rounded-lg"
                          : "rounded-[10px] border-b border-b-[#240B3B] bg-white"
                      }
                      shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]
                      `}
                      onClick={() => {
                        setSelected(item);
                        setIframeSrcMobile("");
                        document.querySelector(".secondBlock").scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }}
                    >
                      <span
                        className={`text-base font-bold ${
                          item.title === selected.title
                            ? "text-pink3"
                            : "text-primary1"
                        }`}
                      >
                        {item.title}
                      </span>
                      <span className="text-pink3 font-bold">+</span>
                    </div>

                    {item.title === selected.title && (
                      <div
                        className={`  col-span-1 md:col-span-2 flex flex-col rounded-2xl px-8 md:px-10 py-6 md:py-10 ${
                          iframeSrcMobile ? "gap-2" : "gap-8"
                        } bg-white
                        shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]
                        `}
                      >
                        <div className="flex flex-col gap-2">
                          <span className="text-xl text-[#240B3B] font-extrabold">
                            {selected.title}
                          </span>
                          <span className="text-md text-[#240B3B] font-medium">
                            {selected.content}
                          </span>
                        </div>

                        {(selected.title === "Promotions That Hit" ||
                          selected.title === "Loyalty: Naturally Built" ||
                          selected.title === "Inventory: Always Right" ||
                          selected.title === "Teams: Perfectly Tuned" ||
                          selected.title === "Decisions: Data-Driven" ||
                          selected.title === "Payments: Click & Secure" ||
                          selected.title === "Branding: Uniquely Yours" ||
                          selected.title === "Bookings Made Easy") && (
                          <div className="w-full">
                            {!iframeSrcMobile && (
                              <img
                                alt={selected.title}
                                onClick={handleImageClickMobile}
                                src={videoImg}
                                className="w-full h-full"
                              />
                            )}
                          </div>
                        )}

                        {selected.title !== "Promotions That Hit" &&
                          selected.title !== "Loyalty: Naturally Built" &&
                          selected.title !== "Inventory: Always Right" &&
                          selected.title !== "Teams: Perfectly Tuned" &&
                          selected.title !== "Decisions: Data-Driven" &&
                          selected.title !== "Payments: Click & Secure" &&
                          selected.title !== "Branding: Uniquely Yours" &&
                          selected.title !== "Bookings Made Easy" && (
                            <div className="w-full">
                              <img
                                alt={selected.title}
                                src={videoImg}
                                className="w-full h-full"
                              />
                            </div>
                          )}

                        {iframeSrcMobile && (
                          <div className="video-container">
                            <iframe
                              className="responsive-iframe"
                              width="560"
                              height="400"
                              src={iframeSrcMobile}
                              title={selected.title}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        )}

                        <div className="border border-[#ABABAB] rounded-2xl flex flex-col md:flex-row items-center px-6 py-4 gap-4 md:gap-8 bg-[#f5f5f5]">
                          <img
                            alt={"VenueBoost Testimonial Avatar"}
                            className="hidden md:block w-[38px] md:w-[58px] h-[38px] md:h-[58px] rounded-full bg-[#ebebe9]"
                            src={selected.avatar}
                          />
                          <div className="flex flex-row items-center justify-start md:hidden w-full gap-4">
                            <img
                              alt={"VenueBoost Review Avatar"}
                              className="w-[38px] md:w-[58px] h-[38px] md:h-[58px] rounded-full bg-[#ebebe9]"
                              src={selected.avatar}
                            />
                            <div className="flex flex-col items-start">
                              <span className="text-lg font-semibold text-[#240B3B]">
                                {selected.owner}
                              </span>
                              <span className="text-gray6 text-sm font-normal">
                                {selected.role}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="hidden md:flex flex-row items-center gap-4">
                              <span className="text-lg font-semibold text-[#240B3B]">
                                {selected.owner}
                              </span>
                              <span className="text-gray6 text-sm font-normal">
                                {selected.role}
                              </span>
                            </div>
                            <span className="text-[#240B3B] text-sm font-normal">
                              {selected.testimonial}
                            </span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            navigateToUrl("/get-started");
                          }}
                          className="w-full md:w-[216px] cursor-pointer rounded py-2 mb-4 md:mb-0 bg-[#240B3B] mx-auto text-center"
                        >
                          <span className="text-lg font-bold text-[#E39DFF]">
                            Try it out
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div className="flex">
                <div
                  className="hidden col-span-1 md:col-span-2 md:flex flex-col rounded-2xl px-8 md:px-10 py-6 md:py-10 gap-8 bg-white ml-[30px] border-b-2 border-b-[#240B3B]
                 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]
                "
                >
                  <div className="flex flex-col gap-2">
                    <span className="text-xl text-[#240B3B] font-extrabold">
                      {selected.title}
                    </span>
                    <span className="text-md text-[#240B3B] font-medium">
                      {selected.content}
                    </span>
                  </div>
                  <div className="w-full">
                    {(selected.title === "Promotions That Hit" ||
                      selected.title === "Loyalty: Naturally Built" ||
                      selected.title === "Inventory: Always Right" ||
                      selected.title === "Teams: Perfectly Tuned" ||
                      selected.title === "Decisions: Data-Driven" ||
                      selected.title === "Payments: Click & Secure" ||
                      selected.title === "Branding: Uniquely Yours" ||
                      selected.title === "Bookings Made Easy") && (
                      <div className="w-full">
                        {!iframeSrcWeb && (
                          <img
                            alt={selected.title}
                            onClick={handleImageClickWeb}
                            src={videoImg}
                            className="cursor-pointer w-full h-full"
                          />
                        )}
                      </div>
                    )}

                    {/*/ TOOD be careful of this when more item  are added*/}
                    {selected.title !== "Promotions That Hit" &&
                      selected.title !== "Loyalty: Naturally Built" &&
                      selected.title !== "Inventory: Always Right" &&
                      selected.title !== "Teams: Perfectly Tuned" &&
                      selected.title !== "Decisions: Data-Driven" &&
                      selected.title !== "Payments: Click & Secure" &&
                      selected.title !== "Branding: Uniquely Yours" &&
                      selected.title !== "Bookings Made Easy" && (
                        <div className="w-full">
                          <img
                            alt={selected.title}
                            src={videoImg}
                            className="w-full h-full"
                          />
                        </div>
                      )}

                    {iframeSrcWeb && (
                      <iframe
                        width="700"
                        height="370"
                        src={iframeSrcWeb}
                        title={selected.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    )}
                  </div>
                  <div className="border border-[#ABABAB] rounded-2xl flex flex-col md:flex-row items-center px-6 py-4 gap-4 md:gap-8 bg-[#f5f5f5]">
                    <img
                      alt={"VenueBoost Testimonial Avatar"}
                      className="hidden md:block w-[38px] md:w-[58px] h-[38px] md:h-[58px] rounded-full bg-[#ebebe9]"
                      src={selected.avatar}
                    />
                    <div className="flex flex-row items-center justify-start md:hidden w-full gap-4">
                      <img
                        alt={"VenueBoost Review Avatar"}
                        className="w-[38px] md:w-[58px] h-[38px] md:h-[58px] rounded-full bg-[#ebebe9]"
                        src={selected.avatar}
                      />
                      <div className="flex flex-col items-start">
                        <span className="text-lg font-semibold text-[#240B3B]">
                          {selected.owner}
                        </span>
                        <span className="text-[#240B3B] text-sm font-normal">
                          {selected.role}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="hidden md:flex flex-row items-center gap-4">
                        <span className="text-lg font-semibold text-[#240B3B]">
                          {selected.owner}
                        </span>
                        <span className="text-gray6 text-sm font-normal">
                          {selected.role}
                        </span>
                      </div>
                      <span className="text-[#240B3B] text-sm font-normal">
                        {selected.testimonial}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigateToUrl("/get-started");
                    }}
                    className="w-full md:w-[216px] cursor-pointer rounded py-2 mb-4 md:mb-0 bg-[#240B3B] mx-auto text-center"
                  >
                    <span className="text-lg font-bold text-[#E39DFF]">
                      Try it out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grow;
