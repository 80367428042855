import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BBLayout from "../../../components/Seo/whitelabel/bybest/BBMainLayout";
import BybestHomeCarousel from "../../../components/BybestHomeCarousel";
import { brandHeaderList, brandList, home_products } from "../data";
import BybestProductItem from "../../../components/BybestProductItem";
import BybestHomeNotiModal from "../../../components/BybestHomeNotiModal";
import useSeoData from "../../../services/hooks/use-seo";
import "./home.css";

const ByBestHomeMobile = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, [30 * 1000]);
  }, []);

  const onGoProductDetails = (p) => {
    navigate(`/products/${p.id}/akeasf`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        {/* Brands Section */}
        <div className="w-full flex justify-center items-center bg-[#3B3B3A] px-4">
          <div className="w-full flex overflow-x-auto px-4 pb-3 pt-4">
            {brandHeaderList.map((item, index) => (
              <div class="icons" key={index}>
                <a href={`/brand/${item.url}`}>
                  <img class="brand-icon" src={item.src} alt={item.name} />
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Products Section */}
        <div className="w-full max-w-screen-main mb-5">
          <BybestHomeCarousel />
          {home_products.map((p, productIndex) => (
            <div key={productIndex} className="px-4">
              <div className="flex justify-between items-center h-20 gap-2">
                <div className="border w-full h-[1px] mb-7"></div>
                {brandHeaderList[productIndex] && (
                  <div class="brand-logo">
                    <div
                      onClick={() =>
                        navigate(`/brands/${brandList[productIndex].url}`)
                      }
                    >
                      <img
                        src={brandList[productIndex].src}
                        data-img-url={brandList[productIndex].src}
                        alt={brandList[productIndex].name}
                      />
                    </div>
                  </div>
                )}

                <div className="border w-full h-[1px] mb-7"></div>
              </div>

              <div className="flex max-w-[100vw] overflow-x-auto md:hidden">
                {p.products.map((pp, index) => (
                  <div key={index} className="pr-3 min-w-[220px]">
                    <BybestProductItem
                      data={pp}
                      type="home"
                      onClick={() => onGoProductDetails(pp)}
                      className="w-[60vw]"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {showModal && (
          <BybestHomeNotiModal
            showModal={showModal}
            onClose={() => setShowModal(false)}
          />
        )}
      </div>
    </BBLayout>
  );
};

export default ByBestHomeMobile;
