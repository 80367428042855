import Svg_fb from "../../assets/svgs/bybest/bb-facebook.svg";
import Svg_inst from "../../assets/svgs/bybest/bb-instagram.svg";
import Svg_linkedin from "../../assets/svgs/bybest/bb-linkedin.svg";
import Svg_pint from "../../assets/svgs/bybest/bb-pinterest.svg";
import Svg_tiktock from "../../assets/svgs/bybest/bb-ticktok.svg";
import Svg_twitter from "../../assets/svgs/bybest/bb-twitter.svg";
import { getFooterTranslation } from "./BBFooter";

export const footerSections = [
  {
    title: getFooterTranslation("customerService"),
    links: [
      { name: getFooterTranslation("help"), href: "help" },
      { name: getFooterTranslation("faq"), href: "frequently-asked-questions" },
    ],
    children: [
      {
        title: getFooterTranslation("careers"),
        links: [
          {
            name: getFooterTranslation("vacantPositions"),
            href: "job-listing",
          },
        ],
      },
    ],
  },
  {
    title: "BY BEST",
    links: [
      { name: getFooterTranslation("contactUs"), href: "contact-us" },
      { name: getFooterTranslation("aboutUs"), href: "about-us" },
      {
        name: getFooterTranslation("storesAndHours"),
        href: "store-and-hours",
      },
      { name: getFooterTranslation("privacyPolicy"), href: "privacy-policy" },
      { name: getFooterTranslation("cookiesPolicy"), href: "cookies" },
    ],
  },
  {
    title: getFooterTranslation("onlineShopping"),
    links: [
      {
        name: getFooterTranslation("pricesAndCurrency"),
        href: "prices-and-currency",
      },
      { name: getFooterTranslation("paymentMethods"), href: "payment-methods" },
      {
        name: getFooterTranslation("shippingMethods"),
        href: "shipping-methods",
      },
      { name: getFooterTranslation("returnPolicy"), href: "return-policy" },
      { name: getFooterTranslation("giftCard"), href: "virtual-gift-card" },
    ],
  },
];

export const socials = [
  {
    href: "https://www.facebook.com/bybest.shop",
    src: Svg_fb,
    label: "Facebook",
  },
  {
    href: "https://www.instagram.com/bybest.shop/",
    src: Svg_inst,
    label: "Instagram",
  },
  {
    href: "https://x.com/ByBestShop",
    src: Svg_twitter,
    label: "Twitter",
  },
  {
    href: "https://www.linkedin.com/company/bybestshop/",
    src: Svg_linkedin,
    label: "LinkedIn",
  },
  {
    href: "https://www.pinterest.com/bybestshop/_saved/",
    src: Svg_pint,
    label: "Pinterest",
  },
  {
    href: "https://www.tiktok.com/@bybestshop",
    src: Svg_tiktock,
    label: "TikTok",
  },
];

export const hasPage = [
  "cookies",
  "prices-and-currency",
  "payment-methods",
  "shipping-methods",
  "return-policy",
  "contact-us",
  "about-us",
  "landing",
  "store-and-hours",
];
