import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";
import { t } from "i18next";

export const getVacanciesTranslation = (label = "") =>
  t(`bybest.vacancies.${label}`);

const ByBestVacancies = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {`>`} {getVacanciesTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <p className=" text-[13px] leading-[25px] text-[#000]">
                {getVacanciesTranslation("commingSoon")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestVacancies;
