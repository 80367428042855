import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { MdOutlineClose } from "react-icons/md";
import "./index.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { getRegisterConfig } from "../../redux/actions/contactsales";
import SvgCard from "../../assets/svgs/bybest/card.svg";
import SvgCash from "../../assets/svgs/bybest/cash.svg";
import { PaymentMethod } from "../../views/bybest/cart-paymentmethod";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import { getBrandTranslation } from "../../views/bybest/brands/brands";
import BktPaymentForm from "../../views/bybest/bkt-payment/BktPaymentForm";

const validator = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  last_name: yup
    .string()
    .trim()
    .notRequired("Last name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  email: yup
    .string()
    .trim()
    .notRequired("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  phone: yup
    .string()
    .trim()
    .required("Phone is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met"),
  country: yup.string().trim().required("Country is required"),
  city: yup.string().trim().required("City is required"),
  address: yup.string().trim().required("Address is required"),
});

const BybestCheckoutModal = ({ app_key, product, showModal, onClose }) => {
  const [open, setOpen] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const isLocalhost = window.location.hostname === "localhost";

  useEffect(() => setOpen(showModal), [showModal]);

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      country: null,
      city: null,
      address: "",
    },
    validationSchema: validator,
    onSubmit: (values) => onSubmit(values),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const paymentMethods = [
    {
      id: "cash",
      img: SvgCash,
      title: "Para ne Dore",
      desc: "Paguaj ne dorezim",
    },
    {
      id: "2checkout",
      img: SvgCard,
      title: "Credit/Debit Card",
      desc: "Paguaj online nepermje...",
    },
  ];
  const [curPaymentMethod, setCurPaymentMethod] = useState("cash");
  const [countries, setCountries] = useState([
    { value: "", title: "Please select" },
  ]);
  const [cities, setCities] = useState([{ value: "", title: "Please select" }]);

  useEffect(() => {
    loadRegisterConfig();
  }, []);

  const loadRegisterConfig = () => {
    getRegisterConfig()
      .then((data) => {
        setCountries(
          data?.countries.map((country) => ({
            value: country.name,
            title: country.name,
            ...country,
          })) || []
        );

        const statesForSelectedCountry = [
          { value: "", title: "Please select" },
          ...data?.countries[0].states?.map((state) => ({
            value: state.name,
            title: state.name,
          })),
        ];
        setCities(statesForSelectedCountry);
      })
      .catch(() => {});
  };

  const handleChange = (e) => {
    formikProps.setFieldValue([e.target.name], e.target.value);

    if (e.target.name === "country") {
      // Get states for the selected country
      const selectedCountry = countries.find(
        (country) => country.value === e.target.value
      );
      const statesForSelectedCountry =
        selectedCountry && selectedCountry.id
          ? [
              { value: "", title: "Please select" },
              ...selectedCountry.states?.map((state) => ({
                value: state.name,
                title: state.name,
              })),
            ]
          : [{ value: "", title: "Please select" }];

      setCities(statesForSelectedCountry);
    }
  };

  const [paymentInfo, setPaymentInfo] = useState(null);

  const onSubmit = (values) => {
    if (!recaptchaToken && !isLocalhost) {
      NotificationManager.error(
        "Please complete the reCAPTCHA to submit the form.",
        "Error",
        3000
      );
      return;
    } else {
      WhiteLabelService.checkoutOrderBybest({
        ...values,
        payment_method: curPaymentMethod,
        token: recaptchaToken,
        order_products: [{ product_id: product.id, product_quantity: 1 }],
        app_key,
      })
        .then((res) => {
          setPaymentInfo(res);
        })
        .catch((error) => {
          NotificationManager.error(
            error.message ||
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
            "Error",
            3000
          );
        });
    }
  };

  if (paymentInfo) {
    return (
      <BktPaymentForm
        paymentUrl={paymentInfo.payment_url}
        paymentData={paymentInfo.payment_data}
      />
    );
  }

  return (
    <Modal
      centered
      closeIcon={false}
      footer={null}
      wrapClassName="bybest-checkoutmodal"
      open={open}
      onCancel={onClose}
    >
      <div className="flex flex-col justify-center items-center w-full">
        <div className="flex justify-center items-center w-full py-3 px-4 border-b border-b-[#DEE2E6]">
          <h1 className="flex-1 text-[20px] font-bold leading-[30px] text-[#000]">
            {getBrandTranslation("buyWithOneClick")}
          </h1>
          <MdOutlineClose size={22} onClick={onClose} />
        </div>
        <div className="w-full p-4">
          <div className="w-full flex justify-center items-center rounded-[5px] p-3 bg-[#3F51B512] border border-[#3F51B5]">
            <img
              alt=""
              src={product.img1}
              className="bg-white w-[70px] h-[80px] rounded-[4px] mr-2"
            />
            <div className="flex-1 ">
              <h3 className=" text-[15px] font-bold leading-[19px] text-[#000]">
                {product.title}
              </h3>
              <p className="text-[13px] leading-[16px] text-[#000]">
                {product.category}
              </p>
              <p className=" mt-3 text-[13px] leading-[16px] text-[#000]">
                {getBrandTranslation("articleNumber")} {product.no}
              </p>
            </div>
          </div>
          <div className="w-full">
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-3">
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getBrandTranslation("name")}*{" "}
                  <span className=" italic">
                    ({getBrandTranslation("required")})
                  </span>
                </p>
                <input
                  name="first_name"
                  placeholder={getBrandTranslation("name")}
                  value={formikProps.values.first_name}
                  error={formikProps.errors.first_name}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#595959] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                />
                <p className="text-[12px] text-[#f00]">
                  {formikProps.errors.first_name}
                </p>
              </div>
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getBrandTranslation("surname")}{" "}
                  <span className=" italic">
                    ({getBrandTranslation("optional")})
                  </span>
                </p>
                <input
                  name="last_name"
                  placeholder={getBrandTranslation("surname")}
                  value={formikProps.values.last_name}
                  error={formikProps.errors.last_name}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#595959] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                />
                <p className="text-[12px] text-[#f00]">
                  {formikProps.errors.last_name}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-3">
              <div>
                <p className="mt-3 text-[12px] leading-[18px] text-[#000]">
                  Email{" "}
                  <span className=" italic">
                    ({getBrandTranslation("optional")})
                  </span>
                </p>
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formikProps.values.email}
                  error={formikProps.errors.email}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#595959] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                />
                <p className="text-[12px] text-[#f00]">
                  {formikProps.errors.email}
                </p>
              </div>
              <div>
                <p className="mt-3 text-[12px] leading-[18px] text-[#000]">
                  {getBrandTranslation("phone")}*{" "}
                  <span className=" italic">
                    {getBrandTranslation("required")}
                  </span>
                </p>
                <PhoneInput
                  country={"al"}
                  inputProps={{
                    name: "phone",
                    placeholder: "Phone",
                  }}
                  value={formikProps.values.phone}
                  error={formikProps.errors.phone}
                  onChange={(v) => {
                    formikProps.setFieldValue("phone", v);
                  }}
                  inputClass="  !w-full !h-[38px] !border-[#595959] !border-[1px] !rounded-[4px] "
                  containerClass="bb-checkout-phone !mt-1"
                />
                <p className="text-[12px] text-[#f00]">
                  {formikProps.errors.phone}
                </p>
              </div>
            </div>
            <p className="mt-4 w-full italic text-[11px] leading-[14px] text-[#000]">
              {getBrandTranslation("choosePrefix")}
              <br />
              {getBrandTranslation("numberStart")}
            </p>
            <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-3">
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">Shteti</p>
                <select
                  name={"country"}
                  value={formikProps.values.country}
                  error={formikProps.errors.country}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#595959] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                >
                  {countries.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <p className="text-[12px] text-[#f00]">
                  {formikProps.errors.country}
                </p>
              </div>
              <div>
                <p className="text-[12px] leading-[18px] text-[#000]">
                  {getBrandTranslation("city")}
                </p>
                <select
                  name={"city"}
                  value={formikProps.values.city}
                  error={formikProps.errors.city}
                  onChange={handleChange}
                  className="mt-1 w-full h-[38px] px-5 border-[#595959] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
                >
                  {cities.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <p className="text-[12px] text-[#f00]">
                  {formikProps.errors.city}
                </p>
              </div>
            </div>
            <p className="mt-3 text-[12px] leading-[18px] text-[#000]">
              {getBrandTranslation("address")}*{" "}
              <span className=" italic">{getBrandTranslation("required")}</span>
            </p>
            <input
              name="address"
              placeholder={getBrandTranslation("address")}
              value={formikProps.values.address}
              error={formikProps.errors.address}
              onChange={handleChange}
              className="mt-1 w-full h-[38px] px-5 border-[#595959] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
            />
            <p className="text-[12px] text-[#f00]">
              {formikProps.errors.address}
            </p>
          </div>
          <div className="mt-4 w-full">
            <p className="text-[12px] leading-[18px] text-[#000]  mb-1">
              {getBrandTranslation("paymentMethods")}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-3">
              {paymentMethods.map((pm, index) => (
                <PaymentMethod
                  key={index}
                  data={pm}
                  isSelected={pm.id === curPaymentMethod}
                  onSelect={(p) => setCurPaymentMethod(p.id)}
                />
              ))}
            </div>
          </div>
          <div className="mt-4 w-full rouned-[4px] p-4 bg-[#F2F2F2]">
            <div className="w-full flex justify-center items-center">
              <input
                name="coupon"
                placeholder={getBrandTranslation("couponCode")}
                className="flex-1 h-[45px] px-3 border-[#767676] border-[2px] rounded-[4px] mr-4"
              />
              <button className="uppercase h-[45px] px-8 bg-[#000] rounded-[4px] text-white text-[14px] font-bold">
                {getBrandTranslation("apply")}
              </button>
            </div>
            <div className="mt-2 w-full flex justify-between items-start">
              <h3 className="font-bold text-[16px] text-[#000] ">Total</h3>
              <div className="flex flex-col justify-end items-end">
                <h3 className="font-bold text-[16px] text-[#000] ">
                  LEK {product?.sale_price}
                </h3>
                <p className="font-medium text-[13px] text-[#000] ">
                  {getBrandTranslation("reward")}{" "}
                  {getBrandTranslation("points")}
                </p>
              </div>
            </div>
          </div>
          <ReCAPTCHA
            sitekey="6Lct7x4lAAAAAFpOMTljbGFY7hqdJnHOvhWvHKhb"
            onChange={handleRecaptchaChange}
            className="mt-4"
          />
          <button
            className="mt-4 w-full h-[41px] rounded-[4px] bg-[#000000a5] text-white text-[15px] font-semibold"
            onClick={formikProps.handleSubmit}
          >
            {getBrandTranslation("orderNow")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BybestCheckoutModal;
