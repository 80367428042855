import React from "react";
import SvgBagSuccess from "../../assets/svgs/bybest/shopping-bag-success.svg";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { getCartTranslation } from "./cart-products";

const ByBestCartSuccess = () => {
  const onSubmit = () => {};

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getCartTranslation("breadcrumb")} {getCartTranslation("success")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full flex flex-col justify-center items-center">
            <img src={SvgBagSuccess} className="w-[150px] h-[150px]" alt="" />
            <h2 className="mt-6 text-[16px] text-[#000] font-bold leading-[24px]">
              {getCartTranslation("orderSuccess")}
            </h2>
            <p className="mt-5 text-[14px] leading-[21px] text-[#000] text-center">
              {getCartTranslation("willNotify")} <br />
              {getCartTranslation("thankyou")}
            </p>
            <button
              className=" mt-4 rounded bg-[#000] h-[45px]  font-bold text-[14px] text-white  "
              onClick={onSubmit}
            >
              {getCartTranslation("continue")}
            </button>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCartSuccess;
