// AuthRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import ByBestVerifyEmail from "../../views/register/verify-email/bb-verify-email.jsx";
import ByBestLogin from "../../views/login/by-best-login.jsx";
import ByBestResetPassword from "../../views/resetPassword/by-best-reset-password.jsx";
import ByBestRegister from "../../views/register/by-best-register.jsx";
import ByBestEmailNotVerified from "../../views/register/not_verified/by-best-not-verified.jsx";
import ByBestContact from "../../views/bybest/contact.js";
import ByBestAbout from "../../views/bybest/about.js";
import ByBestStoreAndHours from "../../views/bybest/store_hours.js";
import ByBestLanding from "../../views/bybest/landing/landing.jsx";
import ByBestBlogs from "../../views/bybest/blog.js";
import ByBestBlogDetails from "../../views/bybest/blog-details.js";
import ByBestHome from "../../views/bybest/home/home.js";
import ByBestCategories from "../../views/bybest/category.js";
import ByBestProductDetails from "../../views/bybest/products/product-details.js";
import ByBestCartProducts from "../../views/bybest/cart-products.js";
import ByBestCartUserInfo from "../../views/bybest/cart-userinfo.js";
import ByBestCartPaymethod from "../../views/bybest/cart-paymentmethod.js";
import ByBestCartSuccess from "../../views/bybest/cart-success.js";
import ByBestClub from "../../views/bybest/myclub.js";
import ByBestOrderTracking from "../../views/bybest/order-tracking/index.js";
import ByBestWishList from "../../views/bybest/wishlist/wishlist.js";
import ByBestVertualGiftCard from "../../views/bybest/virtual-giftcard.js";
import ByBestHelp from "../../views/bybest/help.js";
import ByBestFaqs from "../../views/bybest/faqs.js";
import ByBestVacancies from "../../views/bybest/vacancies.js";
import ByBestPrivacyPolicy from "../../views/bybest/privacypolicy.js";
import ByBestCookies from "../../views/bybest/cookies.js";
import ByBestPriceCurrency from "../../views/bybest/price-currency.js";
import ByBestPaymentMethod from "../../views/bybest/payment-method.js";
import ByBestShippingMethod from "../../views/bybest/shipping-method.js";
import ByBestReturnPolicy from "../../views/bybest/return-policy.js";
import Newsletter from "../../views/bybest/newsletter.jsx";
import DownloadMobileApp from "../../views/bybest/download-mobile-app.jsx";
import ProductSearch from "../../views/bybest/category/product-search.jsx";
import SearchPreview from "../../views/bybest/products/search-preview.jsx";
import TrackingNumber from "../../views/bybest/order-tracking/tracking-number.jsx";
import ByBestLive from "../../views/bybest/live/index.jsx";
import Brands from "../../views/bybest/brands/brands.jsx";
import ByBestRegisterSuccess from "../../views/register/register-successful.jsx";
import ForgotPassword from "../../views/register/forgot-password.jsx";

const bybestGuestRoutes = [
  <Route exact path="" element={<ByBestHome />} />,
  <Route exact path="reset-password" element={<ByBestResetPassword />} />,
  <Route exact path="login" element={<ByBestLogin />} />,
  <Route exact path="register" element={<ByBestRegister />} />,
  <Route exact path="verify-email/:token" element={<ByBestVerifyEmail />} />,
  <Route
    exact
    path="email-not-verified"
    element={<ByBestEmailNotVerified />}
  />,
  <Route
    exact
    path="registered-sucessfully"
    element={<ByBestRegisterSuccess />}
  />,
  <Route exact path="page/contact-us" element={<ByBestContact />} />,
  <Route exact path="page/about-us" element={<ByBestAbout />} />,
  <Route exact path="page/store-and-hours" element={<ByBestStoreAndHours />} />,
  <Route exact path="page/landing" element={<ByBestLanding />} />,
  <Route exact path="virtual-gift-card" element={<ByBestVertualGiftCard />} />,
  <Route exact path="help" element={<ByBestHelp />} />,
  <Route exact path="frequently-asked-questions" element={<ByBestFaqs />} />,
  <Route exact path="job-listing" element={<ByBestVacancies />} />,
  <Route exact path="privacy-policy" element={<ByBestPrivacyPolicy />} />,
  <Route exact path="page/cookies" element={<ByBestCookies />} />,
  <Route
    exact
    path="page/prices-and-currency"
    element={<ByBestPriceCurrency />}
  />,
  <Route exact path="page/payment-methods" element={<ByBestPaymentMethod />} />,
  <Route
    exact
    path="page/shipping-methods"
    element={<ByBestShippingMethod />}
  />,
  <Route exact path="page/return-policy" element={<ByBestReturnPolicy />} />,
  <Route exact path="blog/category/:category" element={<ByBestBlogs />} />,
  <Route
    exact
    path="blog/category/:category/:slug"
    element={<ByBestBlogDetails />}
  />,
  <Route
    exact
    path="products/:product_id/:product_url"
    element={<ByBestProductDetails />}
  />,
  <Route exact path="cart" element={<ByBestCartProducts />} />,
  <Route exact path="checkout" element={<ByBestCartUserInfo />} />,
  <Route exact path="payment" element={<ByBestCartPaymethod />} />,
  <Route exact path="cart/success" element={<ByBestCartSuccess />} />,
  <Route exact path="my-club" element={<ByBestClub />} />,
  <Route exact path="tracking" element={<ByBestOrderTracking />} />,
  <Route exact path="tracking/:tracking_number" element={<TrackingNumber />} />,
  <Route exact path="wishlist" element={<ByBestWishList />} />,
  <Route exact path="newsletter" element={<Newsletter />} />,
  <Route exact path="download-mobile-app" element={<DownloadMobileApp />} />,
  <Route exact path="group/:groupId" element={<ByBestCategories />} />,
  <Route exact path="categories/list" element={<ByBestCategories />} />,
  <Route exact path="category/:slug" element={<ByBestCategories />} />,
  <Route exact path="category/products/search" element={<ProductSearch />} />,
  <Route exact path="products/searchPreview" element={<SearchPreview />} />,
  <Route exact path="collections/list" element={<ByBestCategories />} />,
  <Route exact path="collection/:slug" element={<ByBestCategories />} />,
  <Route exact path="collection/products/search" element={<ProductSearch />} />,
  <Route exact path="brands/list" element={<Brands />} />,
  <Route exact path="brands/:brand_url" element={<ByBestCategories />} />,
  <Route exact path="brands/products/search" element={<ProductSearch />} />,
  <Route exact path="live" element={<ByBestLive />} />,
  <Route exact path="forgot-password" element={<ForgotPassword />} />,
];

export default bybestGuestRoutes;
