import { useLocation, useNavigate, useParams } from "react-router-dom";
import RouteNames from "../constants/route_names";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export function AuthMiddleware({ children }) {
  const { isLoggedIn, isAffilate, user } = useSelector((state) => state.auth);
  const { app_key } = useParams();

  const vbToken = localStorage.getItem("vbToken");
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || !vbToken || vbToken === null) {
      if (isAffilate) {
        if (location.pathname.includes(RouteNames.affiliates_dashboard)) {
          navigate(RouteNames.affiliates_login);
        }
      } else {
        if (app_key) {
          navigate(`/login`);
        } else {
          navigate(RouteNames.login);
        }
      }
    } else {
      if (location.pathname.includes(RouteNames.affiliates_dashboard)) {
        navigate(RouteNames.dashboard);
      }
      if (location.pathname.includes(RouteNames.dashboard)) {
        if (user?.email_verified_at == null) {
          navigate(RouteNames.email_not_verified);
        }
      }
    }
  }, [isAffilate, vbToken, location, isLoggedIn, user?.email_verified_at]);

  return children;
}

export default AuthMiddleware;
