import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import AccommodationHeader from "./accommodation-header";
import AccommodationPhotos from "./accommodation-photos";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { SET_VENUE_DATA } from "../../redux/actions/types";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../utils/common";
import AccommodationServices from "./accommodation-services";
import AccommodationLocation from "./accommodation-location";
import AccommodationInformation from "./accommodation-information";
import AccommodationInformationTwo from "./accommodation-informationTwo";
import AccommodationGuests from "./accommodation- guests";
import AccommodationQuality from "./accommodation-quality/index.js";
import { location } from "./accommodation-location/location-functions.js";
import GoogleMapContainer from "../../components/GoogleMapContainer/index.js";
import { t } from "i18next";

export const getAccommodationText = (label = "") => t(`accommodation.${label}`);

const VenueAccommodation = () => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();

  const { app_key: accommodation_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    WhiteLabelService.getDetails(accommodation_id).then(
      (res) => {
        if (res.allowed_only_from !== "vacation_rental") {
          navigate("/");
        } else {
          dispatch({ type: SET_VENUE_DATA, payload: res.venue });
          setDetails(res.venue);

          identifyVenue(accommodation_id);
          trackEvent("White Label - Accommodation", {
            action_category: "Page Load",
            action_outcome: "success",
            interaction_element: "screen",
            industry: "accommodation",
            source: "vb_web",
          });
        }
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(accommodation_id);
        trackEvent("White Label - Accommodation", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      }
    );
  };

  const TagsBrand = details?.brand_profile?.filter(
    (item) => item.element_name === "Tags"
  )[0];
  const TagsButtonData = {
    text: isEmpty(
      TagsBrand?.venue_brand_profile_customizations?.find(
        (c) => c.customization_key === "text"
      )?.customization_value
    )
      ? TagsBrand?.default_name
      : TagsBrand?.venue_brand_profile_customizations?.find(
          (c) => c.customization_key === "text"
        )?.customization_value,
    bgColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key === "color"
    )?.customization_value,
    textColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key === "text_color"
    )?.customization_value,
  };

  return (
    <div className="flex flex-col">
      <AccommodationHeader TagsButtonData={TagsButtonData} data={details} />

      <AccommodationServices />

      <div className="flex flex-col px-4 md:px-[10%]">
        <div className="gap-10  mx-auto w-full">
          <AccommodationPhotos photos={details?.photos ?? []} />
        </div>
      </div>

      <div className="w-full lg:mt-16 mt-5 px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <div className=" mx-auto w-full mt-5">
          <AccommodationQuality />
        </div>
      </div>

      <div className="">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-5 mx-auto bg-[#F8F9FA] w-full py-16 pb-20 px-4 sm:px-6 md:px-8 lg:px-[10%]">
          <AccommodationLocation
            // openGoogleMaps={openGoogleMaps(accommodation_id)}
            location={location(accommodation_id)}
            vRlink={location(accommodation_id).vr_link}
            accId={accommodation_id}
            address={details?.overview?.address}
          />
          <AccommodationInformation
            accId={accommodation_id}
            // openGoogleMaps={openGoogleMaps}
            vRlink={location(accommodation_id).vr_link}
            // openVR={openVR()}
          />
          <AccommodationInformationTwo
            accId={accommodation_id}
            // openGoogleMaps={openGoogleMaps}
            vRlink={location(accommodation_id).vr_link}
            // openVR={openVR()}
          />
        </div>
        <div className="w-full pb-16 px-4 sm:px-6 md:px-8 lg:px-[10%] -mt-12">
          <GoogleMapContainer
            accId={accommodation_id}
            location={location(accommodation_id)}
            address={details?.overview?.address}
            showSearch={false}
          />
        </div>
      </div>

      <AccommodationGuests />
    </div>
  );
};

export default VenueAccommodation;
