import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTranslation } from "../landing/landing";
import { brands, checkboxIcon } from "../landing/data";
import "../landing/landing.css";

const RegisterForm = ({
  onSubmit,
  isMyClub = false,
  resetForm = false,
  isLoading = false,
}) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone] = useState("");
  const [preferred_brand_id, setPreferredBrand] = useState("");
  const [first_name, setName] = useState("");
  const [last_name, setSurname] = useState("");
  const [birthday, setBirthday] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();

  const handleSubmit = () => {
    const payload = {
      full_name: isMyClub ? undefined : full_name,
      first_name: isMyClub ? first_name : undefined,
      last_name: isMyClub ? last_name : undefined,
      email,
      phone_number,
      preferred_brand_id,
      birthday: isMyClub ? birthday : undefined,
      city: isMyClub ? city : undefined,
      address,
    };

    onSubmit(payload, app_key);
  };

  useEffect(() => {
    if (resetForm) {
      setFullName("");
      setEmail("");
      setPhone("");
      setPreferredBrand("");
      setName("");
      setSurname("");
      setBirthday("");
      setCity("");
      setAddress("");
      setAcceptTerms(false);
    }
  }, [resetForm]);

  return (
    <div className="register-member col-span-1 lg:col-span-6 xl:col-span-5">
      <div className="flex flex-col">
        <span className="title">{getTranslation("registerBlockTitle")}</span>
      </div>

      <div className="mt-2 w-full grid grid-cols-1 gap-4">
        {isMyClub ? (
          <>
            <div className="flex flex-col gap-2">
              <span className="name">{getTranslation("nameLabel")}</span>
              <input
                placeholder={getTranslation("nameLabel")}
                value={first_name}
                onChange={(e) => setName(e.target.value)}
                className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="name">{getTranslation("surnameLabel")}</span>
              <input
                placeholder={getTranslation("surnameLabel")}
                value={last_name}
                onChange={(e) => setSurname(e.target.value)}
                className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="name">{getTranslation("birthdayLabel")}</span>
              <input
                type="date"
                placeholder={getTranslation("birthdayPlaceholder")}
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="name">{getTranslation("cityLabel")}</span>
              <input
                placeholder={getTranslation("cityLabel")}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="name">{getTranslation("addressLabel")}</span>
              <input
                placeholder={getTranslation("addressLabel")}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-2">
            <span className="name">{getTranslation("fullNameLabel")}</span>
            <input
              placeholder={getTranslation("fullNamePlaceholder")}
              value={full_name}
              onChange={(e) => setFullName(e.target.value)}
              className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
            />
          </div>
        )}
        <div className="flex flex-col gap-2">
          <span className="name">{getTranslation("emailLabel")}</span>
          <input
            className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
            placeholder={getTranslation("emailPlaceholder")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="name">{getTranslation("phoneLabel")}</span>
          <input
            className="w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
            placeholder={getTranslation("phonePlaceholder")}
            value={phone_number}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="name">{getTranslation("preferredBrandLabel")}</span>
          <select
            name="brand"
            className="pr-5 cursor-pointer mt-1 w-full h-12 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-gray-300"
            value={preferred_brand_id}
            onChange={(e) => setPreferredBrand(e.target.value)}
          >
            {brands.map((item, index) => (
              <option
                key={index}
                value={item.value}
                disabled={item.value === ""}
              >
                <span>{item.title}</span>
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-4 flex items-center gap-2 w-full">
        <input
          type="checkbox"
          id="acceptTerms"
          checked={acceptTerms}
          onChange={(e) => setAcceptTerms(e.target.checked)}
          className="!hidden"
        />
        <label htmlFor="acceptTerms" className="label flex items-center gap-2">
          <div
            className={`w-5 h-5 flex justify-center items-center border-2 rounded-full ${
              acceptTerms
                ? "bg-[#cb0000] border-[#cb0000]"
                : "bg-white border-gray-300"
            }`}
          >
            {acceptTerms && <span>{checkboxIcon}</span>}
          </div>
          <span>
            {getTranslation("acceptTermsLabel")}{" "}
            <span
              className="underline"
              onClick={() => navigate(`/page/privacy-policy`)}
            >
              {" "}
              {getTranslation("privacy_policy")}{" "}
            </span>
            {getTranslation("OfBybest")}
          </span>
        </label>
      </div>

      <div className="flex flex-col gap-2 w-full items-start">
        <button
          disabled={!acceptTerms || isLoading}
          className={`bg-[#cb0000] text-white ${
            !acceptTerms || isLoading ? "opacity-60 cursor-not-allowed" : ""
          }`}
          onClick={handleSubmit}
        >
          {isLoading
            ? "Loading..."
            : getTranslation("registerAndGetDiscountButton")}
        </button>
        <span className="small-label">
          {getTranslation("registrationfree")}
        </span>
      </div>
    </div>
  );
};

export default RegisterForm;
