import { DatePicker, Modal } from "antd";
import React from "react";
import "./index.css";

const RentalModal = ({
  openModal,
  handleCloseModal,
  handleSaveModal,
  modalCheckIn,
  modalCheckOut,
  show,
  isDateInRange,
  setModalCheckIn,
  setModalCheckOut,
}) => {
  return (
    <Modal
      centered
      open={openModal}
      onCancel={() => handleCloseModal()}
      onOk={() => handleSaveModal()}
      maskClosable={false}
      okText={"Save"}
      okButtonProps={{
        style: { backgroundColor: "#6DDFE2", color: "white" },
        disabled: !modalCheckIn || !modalCheckOut,
      }}
      cancelButtonProps={{
        style: {
          backgroundColor: "transparent",
          color: "#240b3b",
          borderColor: "#6DDFE2",
        },
      }}
      width={680}
    >
      <div
        className={"flex flex-col py-4 w-full h-max md:h-[450px] overflow-auto"}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 w-full flex-col gap-4 md:gap-10 ">
          <div className="flex flex-col">
            <p className="text-12 text-[#6DDFE2] font-bold leading-[16px] mb-2">
              CHECK-IN
            </p>
            <DatePicker
              className="hidden md:block"
              open={show}
              disabledDate={isDateInRange}
              onChange={(e) => setModalCheckIn(e)}
            />
            <DatePicker
              className="block md:hidden"
              disabledDate={isDateInRange}
              onChange={(e) => setModalCheckIn(e)}
            />
          </div>
          <div className="flex flex-col">
            <p className="text-12 text-[#6DDFE2] font-bold leading-[16px] mb-2">
              CHECK-OUT
            </p>
            <DatePicker
              className="hidden md:block text-[#6DDFE2]"
              open={show}
              disabledDate={isDateInRange}
              onChange={(e) => setModalCheckOut(e)}
            />
            <DatePicker
              className="block md:hidden text-[#6DDFE2]"
              disabledDate={isDateInRange}
              onChange={(e) => setModalCheckOut(e)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RentalModal;
