import { ChevronRight } from "@mui/icons-material";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";

export const CollapseMenu = ({
  menu,
  isOpened,
  onSelect,
  onCloseDrawer,
  className = "",
}) => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();

  const goCategory = (category, item) => {
    onCloseDrawer();

    if (menu?.id === 11) {
      navigate(`/group/10`);
      onCloseDrawer();
    } else if (menu.label === "Blog") {
      navigate(`/blog/category/${item}`);
    } else {
      navigate(`/category/${item.replace(/\s+/g, "-").toLowerCase()}`, {
        state: {
          category: category,
          item: item,
        },
      });
    }
  };

  return (
    <>
      <div
        className={`w-full flex justify-center items-center px-4 py-3 ${className}`}
        onClick={() => onSelect(menu)}
      >
        <div className="flex-1">
          <div className="no-underline text-black font-medium text-3 w-full uppercase">
            {menu.label}
          </div>
        </div>
        <ChevronRight fontSize={"small"} />
      </div>
      {isOpened && (
        <div className="w-full px-4 bg-[#f4f4f4]">
          {menu.menus.map((item, i) => {
            return (
              <div
                key={i}
                className="flex flex-col justify-start items-start py-3"
              >
                {item.category ? (
                  <>
                    <p className="text-[26px] text-[#000]">{item.category}</p>
                    {item.items?.map((_item, index) => (
                      <div
                        key={index}
                        className="flex justify-start items-center mt-5 no-underline text-[#000] text-[12px] w-full"
                        onClick={() => goCategory(item.category, _item)}
                      >
                        {_item}
                        {(_item === "Flik flak" || _item === "SWATCH") && (
                          <img
                            alt=""
                            src="https://bybest.shop/assets/img/icons/swiss.png"
                            className="ml-1 h-[13px] object-contain"
                          />
                        )}
                      </div>
                    ))}
                    <p className="mt-5 text-[13px] font-bold text-[#000] uppercase">
                      {t("bybest.header.see_all")}
                    </p>
                  </>
                ) : (
                  <div
                    className="flex justify-start items-center mt-5 no-underline text-[#000] text-[12px] w-full"
                    onClick={() => goCategory(item.category, item.label)}
                  >
                    {item.label}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
