import React from "react";
import Carousel from "react-bootstrap/Carousel";
import slider1 from "../../assets/images/bybest/blogs/slider1.jpg";
import slider2 from "../../assets/images/bybest/blogs/slider2.png";
import slider3 from "../../assets/images/bybest/blogs/slider3.png";
import slider4 from "../../assets/images/bybest/blogs/slider4.png";
import slider5 from "../../assets/images/bybest/blogs/slider5.jpg";
import slider6 from "../../assets/images/bybest/blogs/slider6.jpg";
import slider7 from "../../assets/images/bybest/blogs/slider7.png";
import slider8 from "../../assets/images/bybest/blogs/slider8.jpeg";
import slider9 from "../../assets/images/bybest/blogs/slider9.png";
import slider10 from "../../assets/images/bybest/blogs/slider10.png";

const BybestHomeCarousel = () => {
  const data = [
    { title: "SWAROVSKI", img: slider1 },
    { title: "SWAROVSKI", img: slider2 },
    { title: "SWAROVSKI", img: slider3 },
    { title: "SWAROVSKI", img: slider4 },
    { title: "SWAROVSKI", img: slider5 },
    { title: "SWAROVSKI", img: slider6 },
    { title: "SWAROVSKI", img: slider7 },
    { title: "SWAROVSKI", img: slider8 },
    { title: "SWAROVSKI", img: slider9 },
    { title: "SWAROVSKI", img: slider10 },
  ];

  return (
    <div className="w-full relative homeSlider">
      <Carousel>
        {data.map((item, index) => (
          <Carousel.Item key={index}>
            <img src={item.img} className="w-[100%] object-cover" alt="" />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default BybestHomeCarousel;
