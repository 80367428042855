import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { storeData } from "./data";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";
import { t } from "i18next";

export const getStoresTranslation = (label = "") =>
  t(`bybest.storesAndHours.${label}`);

const ByBestStoreAndHours = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000]">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {`>`} {getStoresTranslation("storesAndHours")}
          </p>
        </div>
        <div className="w-full max-w-screen-main mt-7 mb-5">
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className="col-span-7">
              {storeData.map((store, index) => (
                <div
                  key={index}
                  className="mt-8 w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8"
                >
                  <div>
                    <h3 className="text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                      {store.name}
                    </h3>
                    <p className="text-[12px] leading-[20px] text-[#000]">
                      {store.address}
                    </p>
                    <p className="text-[12px] leading-[20px] text-[#000]">
                      {store.city}
                    </p>
                  </div>
                  <div>
                    <p className="text-[12px] leading-[20px] text-[#000]">
                      Tel. <span className="underline">{store.phone}</span>
                    </p>
                    <p className="text-[12px] leading-[20px] text-[#000]">
                      Tel. <span className="underline">{store.phone}</span>
                    </p>
                    <p className="text-[12px] leading-[20px] text-[#000]">
                      {getStoresTranslation("timesheet")}: {store.workingHours}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestStoreAndHours;
