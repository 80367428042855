import React, { useState } from "react";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import { t } from "i18next";
import "./index.css";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";

export const getRegisterTranslation = (label = "") =>
  t(`bybest.register.${label}`);

const ByBestRegisterSuccess = () => {
  const { app_key = "BYB2802APPY" } = useParams();

  const [loading, setLoading] = useState(false);

  const registerToken = localStorage.getItem("registerToken");

  const onSubmit = () => {
    setLoading(true);
    WhiteLabelService.resendRegistration(
      {
        source: "bybest.shop_web",
      },
      app_key,
      registerToken
    )
      .then(() => {
        NotificationManager.success(t("bybest.email.resend"), "Success");
        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || "Something went wrong!",
          "Error",
          3000
        );
        setLoading(false);
      });
  };

  return (
    <BBMainLayout clientKey="bybest">
      <div className={"align-col-middle view-terms my-12 register"}>
        <div className="flex flex-col items-start justify-center gap-5 px-5 md:px-0">
          <div className="w-full flex justify-center items-center">
            <div className="w-full flex justify-center items-center">
              <div className="lg:flex items-center gap-1">
                <span className="bybest-title-size">
                  {getRegisterTranslation("emailRegistered")}
                </span>{" "}
                <strong className="bybest-title-size">
                  {getRegisterTranslation("success")}
                </strong>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <span className="decription max-w-screen-sm">
              {getRegisterTranslation("subtitle")}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-center mt-8">
          <div>
            <button
              disabled={loading}
              className="bg-[#3b3b3a] text-white font-bold py-2 px-4 rounded"
              onClick={onSubmit}
            >
              {loading ? (
                <span className="flex flex-wrap">
                  <Spin />{" "}
                  <span>{getRegisterTranslation("detailsButton")}</span>
                </span>
              ) : (
                getRegisterTranslation("detailsButton")
              )}
            </button>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestRegisterSuccess;
