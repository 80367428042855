import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { getBrandTranslation } from "./brands/brands";
import { t } from "i18next";

export const getAboutTranslation = (label = "") => t(`bybest.about.${label}`);

const ByBestAbout = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {`>`} {getAboutTranslation("aboutUs")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className="col-span-7">
              <div className="w-full">
                <h3 className="text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  {getAboutTranslation("aboutTitle")}
                </h3>
                <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  {getAboutTranslation("aboutDescription")}
                </p>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  {getAboutTranslation("aboutMonth")}
                </h3>
                <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  {getAboutTranslation("bybestTime")}
                </p>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  {getAboutTranslation("year")}
                </h3>
                <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  {getAboutTranslation("bybestCopyright")}
                </p>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  {getAboutTranslation("november")}
                </h3>
                <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  {getAboutTranslation("freeLtd")}
                </p>
                <ul className="mt-2 list-disc pl-5">
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    {getAboutTranslation("shoppingCenter")}
                  </li>
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    {getAboutTranslation("touristVillages")}
                  </li>
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    {getAboutTranslation("internationalTrade")}
                  </li>
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    {getAboutTranslation("foodAndBeverage")}
                  </li>
                </ul>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  {getAboutTranslation("years")}
                </h3>
                <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  {getAboutTranslation("bybestDutyFree")}
                </p>
              </div>
              <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8 mt-7">
                <div>
                  <h3 className="text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                    {getAboutTranslation("philosophy")}
                  </h3>
                  <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                    {getAboutTranslation("philosophyDescription")}
                  </p>
                  <h3 className="mt-4 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                    {getAboutTranslation("vision")}
                  </h3>
                  <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                    {getAboutTranslation("visionDescription")}
                  </p>
                </div>
                <div>
                  <h3 className="mt-4 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                    {getAboutTranslation("mission")}
                  </h3>
                  <p className="mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                    {getAboutTranslation("missionDescription")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestAbout;
