import React, { useEffect } from "react";
import "./index.css";
import ScrollToUpIcon from "../../assets/svgs/scroll-up.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShowTopBtn } from "../../redux/actions/app";
import { useLocation } from "react-router-dom";
import { BsArrowUp } from "react-icons/bs";

const ScrollToTopButton = () => {
  const dispatch = useDispatch();
  const showTopBtn = useSelector((state) => state.app.showTopBtn);
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        dispatch(setShowTopBtn(true));
      } else {
        dispatch(setShowTopBtn(false));
      }
    });
    // eslint-disable-next-line
  }, []);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isAccommodation =
    pathname.includes("venue/accommodation") || pathname.startsWith("/rental/");

  return (
    <div className=" sm:hidden relative z-999">
      {showTopBtn === true && (
        <div
          className="rounded-[50%] fixed bottom-[85px] right-8 z-[1000]"
          onClick={() => goToTop()}
        >
          {isAccommodation ? (
            <div className="bg-[#6DDFE2] h-[58px] w-[58px] rounded-[50%] flex justify-center items-center">
              <BsArrowUp className="text-white text-24" />
            </div>
          ) : (
            <img
              src={ScrollToUpIcon}
              className="w-[58px] h-[58px]"
              alt="icon"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
