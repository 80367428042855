import React, { useState } from "react";
import cartIcon from "../../assets/svgs/bybest/bb-cart.svg";
import headerIcon from "../../assets/svgs/bybest/bb-lang.svg";
import heartIcon from "../../assets/svgs/bybest/bb-heart.svg";
import userIcon from "../../assets/svgs/bybest/bb-user.svg";
import searchIcon from "../../assets/svgs/bybest/bb-search.svg";
import BybestDropdownMenu from "../../components/BybestHeaderMenu";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderDrawer from "./header-drawer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeBybestLanguage } from "../../redux/actions/app";
import "./index.css";
import { menuItems } from "./data";
import { useLocalStorage } from "../../services/hooks/use-localstorage";
import { FaHeart, FaShoppingCart } from "react-icons/fa";
import MobileHeader from "./mobile-header";

const BBHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.app.bybestLanguage);
  const [productList] = useLocalStorage("wishlist", []);
  const { app_key = "BYB2802APPY" } = useParams();
  const [open, setOpen] = useState(false);

  const getTranslation = (label = "") => t(`bybest.header.${label}`);

  const onWishList = () => {
    navigate(`/wishlist`);
  };

  const onShoppingCart = () => {
    navigate(`/cart`);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeBybestLanguage(newLanguage));
    window.location.reload();
  };

  const headerIcons = [
    {
      onClick: onWishList,
      src: heartIcon,
      icon: <FaHeart style={{ fontSize: 24 }} aria-hidden="true" />,
      badgeCount: productList.length,
    },
    {
      onClick: onShoppingCart,
      icon: <FaShoppingCart style={{ fontSize: 24 }} aria-hidden="true" />,
      src: cartIcon,
    },
    {
      to: `/my-club`,
      label: getTranslation("myClub"),
    },
    {
      to: `/tracking`,
      label: getTranslation("trackOrder"),
    },
    {
      to: `/login`,
      src: userIcon,
      desktopOnly: true,
    },
  ];

  return (
    <>
      <div className="flex justify-center items-center w-full bg-white z-10 top-[32px] fixed border-b border-b-[#F4F4F4]sm:px-6 md:px-8 lg:px-[10%] px-4 sm:px-6">
        <div className="w-full max-w-screen-main hidden md:flex justify-center items-center">
          <div className="flex flex-col pt-2 bg-white">
            <div className="bg-white flex justify-between">
              <div className="menu-header grid grid-cols-2">
                <div className="left-side gap-0 flex items-center">
                  <img alt="" src={headerIcon} />
                  <span className="lang cursor-pointer pl-2">
                    {getTranslation("languageLabel")}
                  </span>
                  <select
                    className="choose-lang cursor-pointer bg-transparent outline-none border-none"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <option value="al">AL</option>
                    <option value="en">EN</option>
                  </select>
                  <select className="bold-label cursor-pointer bg-transparent outline-none border-none">
                    <option value="LEK">{getTranslation("currencyLek")}</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center lg:justify-end justify-between w-full gap-2">
                {headerIcons.map((icon, index) => (
                  <div
                    key={index}
                    className={`my-club relative ${
                      icon.desktopOnly ? "hidden md:block" : ""
                    }`}
                    onClick={icon.onClick}
                  >
                    {icon.badgeCount > 0 && (
                      <span className="icon-absolute !text-white">
                        {icon.badgeCount}
                      </span>
                    )}
                    {icon.src ? (
                      <img alt="" src={icon.src} />
                    ) : (
                      icon.icon || (
                        <Link to={icon.to}>
                          <span>{icon.label}</span>
                        </Link>
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <Link to={``}>
                <img
                  alt="bybestlogo"
                  src="https://bybest.shop/assets/img/bybest-logo.png"
                  className="w-[211px] h-[52px] object-contain"
                />
              </Link>
            </div>
            <div className="w-full mt-3 px-5 xl:px-0">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-1" />
                <div className="flex justify-center items-center col-span-9 w-full">
                  <div className="flex items-center w-[100vw] overflow-hidden overflow-x-auto justify-center h-[44px]">
                    {menuItems.map((item, index) => (
                      <BybestDropdownMenu key={index} menu={item} />
                    ))}
                  </div>
                </div>
                <div className="relative w-full col-span-2 py-1 px-1">
                  <div className="flex justify-end border-b border-b-[#333333] py-1 pb-1 px-1">
                    <span className="absolute left-0 inset-y-0 flex items-center h-[40px]">
                      <img alt="" src={searchIcon} />
                    </span>
                    <input
                      autoComplete="off"
                      type="search"
                      placeholder={getTranslation("searchPlaceholder")}
                      className="pl-5 pr-2 w-full border-none outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:flex md:h-[164px]" />

      <MobileHeader {...{ open, setOpen, app_key, headerIcons }} />

      <div className="flex md:hidden h-[80px] pt-2" />
      <HeaderDrawer {...{ app_key, open }} onClose={() => setOpen(false)} />
    </>
  );
};

export default BBHeader;
