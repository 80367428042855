import React from 'react';
import SophiaNAvatar from '../../../assets/svgs/sophia-vb-testimonial.png';
import EmilyMAvatar from '../../../assets/svgs/emily-vb-testimonial.png';
import JoseGAvatar from '../../../assets/svgs/jose-vb-testimonial.png';
import AidenRAvatar from '../../../assets/svgs/aiden-vb-testimonial.png';
import { motion } from 'framer-motion';
import Carousel from 'react-multi-carousel';

import './index.css';
import { vbText } from '..';

const cardVariants = {
  offscreen: {
    scale: 0.8,
    opacity: 0,
  },
  onscreen: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.1,
      duration: 0.8,
    },
  },
};

const list = [
  {
    avatar: EmilyMAvatar,
    name: 'Emily',
    job: 'Cafe Owner, Chicago, IL',
    // tag: "NY",
    desc:
      'As a busy mom-and-pop cafe owner, VenueBoost has been a total game changer. The system is so\n' +
      'intuitive - I easily update menus, track guest preferences, send promotions, and monitor analytics in one\n' +
      'place. With VenueBoost handling the technology, I have more time to chat with my regulars and get\n' +
      'creative in the kitchen. I don`t know how I managed before!',
  },
  {
    avatar: AidenRAvatar,
    name: 'Aiden',
    job: 'Retail Chain Founder and Owner, New Haven, CT',
    // tag: "CA",
    desc:
      'VenueBoost has been an incredible help to us as we grow from a small retail store into a larger home\n' +
      'goods chain. VenueBoost is able to execute on the core parts of our business - inventory management and\n' +
      'the customer experience - so that we can fulfill orders quickly, understand buying trends, and be\n' +
      'consistent with promotions. VenueBoost has become an essential partner to our business, and I`m excited\n' +
      'to see what comes next!',
  },
  {
    avatar: JoseGAvatar,
    name: 'José',
    job: 'Hotel General Manager, Austin, TX',
    // tag: "MAPLEWOOD COUNTRY CLUB",
    desc:
      'As General Manager of a 100-room hotel, I can`t express enough how much easier VenueBoost has\n' +
      'made managing everything - from housekeeping to reservations to payroll and invoices. It`s all\n' +
      'streamlined now rather than cobbled together like our previous system. I finally have the data-driven\n' +
      'insights to be proactive and efficient in running our property.',
  },
  {
    avatar: SophiaNAvatar,
    name: 'Sophia',
    job: 'Golf Course Manager, Miami, FL',
    // tag: "Bowling Alley Manager, OH",
    desc:
      'VenueBoost has been a great addition to our golf club. We have had no issues and the software is very\n' +
      'easy to use. The people at VenueBoost are always there to help and respond quickly. I would recommend\n' +
      'this software to any business that needs a membership program.',
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 2050 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 2050, min: 1240 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1240, min: 768 },
    items: 2,
  },
  mobile1: {
    breakpoint: { max: 768, min: 414 },
    items: 1,
  },
  mobile2: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const BusinessWorldwide = () => {
  return (
    <div className={'align-col-middle view-terms mt-0'}>
      <div className="w-[100vw] relative ">
        <div className="mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-4 py-8 md:pt-24">
          <div className="flex flex-col items-center pb-5 gap-4">
            <span className="text-28 md:text-44 font-black text-primary1 text-center">
              {vbText('TrustedByBusinessesWorldwide')}
            </span>
            <span className="text-sm md:text-xl text-gray4 text-semibold text-left md:text-center">
              {vbText('VenueBoostHelpedVariousVenuesDelightCustomers')}
              <br />
              {vbText('ImproveOperationsAndAccelerateGrowth')}
            </span>
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-14 md:flex-row justify-center mt-16"> */}
          <div className="md:pl-11">
            <Carousel
              responsive={responsive}
              infinite={true}
              // autoPlay={true}
              showDots={true}
              partialVisible={false}
              containerClass={'py-10 md:py-14 home-carousel'}
              arrows={false}
            >
              {list &&
                list.length &&
                list.map((item, index) => (
                  <motion.div
                    className="flex"
                    key={index}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <motion.div variants={cardVariants} className="flex">
                      <div className="aboutus-card flex flex-col border-[1px] rounded-[13px] bg-white border-[#E0E0E0] p-6 w-full md:w-[590px] h-[280px] md:h-80">
                        <div className="flex flex-row align-middle">
                          <img
                            src={item.avatar}
                            className="w-[38px] md:w-[58px] h-[38px] md:h-[58px] rounded-full mr-6"
                          />
                          <div className="flex flex-col flex-1">
                            <p className="text-[15px] md:text-20 font-semibold text-[#151515]">
                              {item.name}
                            </p>
                            <p className="text-[8px] md:text-14 font-medium text-[#999999]">
                              {item.job}
                            </p>
                          </div>
                          <p className=" text-[#151515] text-12 w-[100px] text-center font-bold  rounded-[6px]">
                            {/* <img src=""></img> */}
                            {item.tag}
                          </p>
                        </div>
                        <div className="text-[13px] md:text-18 font-normal text-[#666666] mt-3 md:mt-6">
                          {item.desc}
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessWorldwide;
