import { t } from "i18next";
import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import "./index.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const getPassText = (label = "") => t(`bybest.forgotPassword.${label}`);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <div className="main-row center">
      <div className="card">
        <form
          className="flex flex-col gap-3 items-start"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col">
            <span className="text-16 font-extrabold">
              {getPassText("askNewPass")}
            </span>
            <span className="text-12 opacity-80">{getPassText("setInfo")}</span>
          </div>
          <div className="flex flex-col items-start w-full">
            <label htmlFor="email" className="text-14">
              {getPassText("email")}
            </label>
            <div className="relative w-full">
              <input
                id="email"
                type="email"
                className={`input-email outline-none w-full ${
                  error ? "border !border-red-700" : ""
                }`}
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                tabIndex={-1}
              />
              {error && (
                <AiOutlineInfoCircle className="absolute right-2 top-1/2 transform -translate-y-1/2 text-red-500" />
              )}
            </div>
            {error && (
              <span className="text-red-500 text-12">
                The email field is required.
              </span>
            )}{" "}
          </div>
          <button
            type="submit"
            id="payment_submit"
            className="btn-primary w-full"
          >
            <span>{getPassText("setPass")}</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
