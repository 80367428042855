import React from "react";
import "./index.css";
import SvgHomeGrowth from "../../../assets/images/homegrowth.webp";
import SvgHomeDot from "../../../assets/svgs/homedot.webp";
import { t } from "i18next";

export const getGrowthTranslation = (label = "") => t(`home.growth.${label}`);

const Growth = () => {
  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <div className="bg-white w-[100vw] relative">
        <div className="mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16">
          <div className="flex flex-col md:flex-row pt-8 md:pt-20 pb-8 md:pb-24">
            <div className="flex flex-col w-full md:w-[33%]">
              <img
                alt={t("home.testimonials.bookingsMadeEasy.title")}
                src={SvgHomeGrowth}
                className="w-full h-full"
              />
            </div>
            <div className="flex flex-col items-center md:items-start w-full md:w-[67%] pl-0 md:pl-11">
              <h2 className="text-primary1 font-extrabold text-28 md:text-44 mt-6 md:mt-0 text-center md:text-left">
                {getGrowthTranslation("supportingYourGrowth")}
              </h2>
              <p className="mt-3 text-[#666A73] font-semibold text-sm md:text-xl leading-5 md:leading-7 text-center md:text-left">
                {getGrowthTranslation("extensiveResources")}
              </p>
              <div className="my-10 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8">
                <a
                  aria-label={getGrowthTranslation("connectWithUs")}
                  href="/contact-us"
                  className="flex flex-row justify-start items-center"
                >
                  <img
                    src={SvgHomeDot}
                    className="w-[24px] h-[24px] md:w-[30px] md:h-[30px]"
                    alt={getGrowthTranslation("connectWithUs")}
                  />
                  <span className="ml-4 text-[#240B3B] font-extrabold text-14 md:text-16">
                    {getGrowthTranslation("connectWithUs")}
                  </span>
                </a>
                <a
                  aria-label={getGrowthTranslation("checkOurBlog")}
                  href="/blog"
                  className="flex flex-row justify-start items-center"
                >
                  <img
                    src={SvgHomeDot}
                    className="w-[24px] h-[24px] md:w-[30px] md:h-[30px]"
                    alt={getGrowthTranslation("checkOurBlog")}
                  />
                  <span className="ml-4 text-[#240B3B] font-extrabold text-14 md:text-16">
                    {getGrowthTranslation("checkOurBlog")}
                  </span>
                </a>
                <a
                  aria-label={getGrowthTranslation("exploreBestPractices")}
                  href="/resources/best-practices"
                  className="flex flex-row justify-start items-center"
                >
                  <img
                    src={SvgHomeDot}
                    className="w-[24px] h-[24px] md:w-[30px] md:h-[30px]"
                    alt={getGrowthTranslation("exploreBestPractices")}
                  />
                  <span className="ml-4 text-[#240B3B] font-extrabold text-14 md:text-16">
                    {getGrowthTranslation("exploreBestPractices")}
                  </span>
                </a>
              </div>
              <div className="flex flex-col md:flex-row justify-center md:justify-start items-center">
                <a
                  aria-label={getGrowthTranslation("unlockFreePlan")}
                  href="/get-started"
                  className="get-started rounded-md w-max px-9 h-[54px] flex items-center"
                >
                  <span className="text-base text-gray2 font-black">
                    {getGrowthTranslation("unlockFreePlan")}
                  </span>
                </a>
                <a
                  aria-label={getGrowthTranslation("visitResourceCenter")}
                  href="/resources"
                  className="ml:0 md:ml-5 mt-6 md:mt-0 text-[#240B3B] font-semibold text-14 md:text-16"
                >
                  {getGrowthTranslation("visitResourceCenter")} {">"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Growth;
