import { SET_VENUE_DATA } from "./types";
import WhiteLabelService from "../../services/whiteLabelService";

export const loadWhiteLabel = (app_key) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    WhiteLabelService.getDetails(app_key).then(
      (res) => {
        dispatch({
          type: SET_VENUE_DATA,
          payload: { ...res.venue, currency: res.currency },
        });
        resolve();
      },
      (error) => {
        reject(error);
      }
    );
  });
};
