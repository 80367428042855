import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import BybestCartStepper from "../../components/BybestCartStepper";
import ImgGed from "../../assets/images/bybest/ged.png";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { getBrandTranslation } from "./brands/brands";
import { getCartTranslation } from "./cart-products";

const ByBestCartUserInfo = () => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const [product] = useState({
    id: 5,
    title: "Byzylyk Idyllia",
    category: "Guaska, rozë, e veshur me rodium",
    img1: "https://admin.bybest.shop/storage/products/62447fc3e1f44_KLE20QB101-01 frusta.jpg",
    img2: "https://admin.bybest.shop/storage/products/62447fc3ecd4b_KLE20QB101-03.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  });

  const onSubmit = () => {
    navigate(`/payment`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            {getBrandTranslation("breadcrumb")} {getCartTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="flex justify-start items-center mb-5">
            <FaChevronLeft color="#000" size={20} />
            <span className=" ml-1 text-[16px] text-[#000] font-semibold">
              {getCartTranslation("continue")}
            </span>
          </div>
          <BybestCartStepper curStep={1} />
          <h1 className="mt-5 text-[16px] text-[#000] leading-[24px] font-bold uppercase">
            {getCartTranslation("data")}
          </h1>
          <p className="text-[13px] text-[#000] leading-[19px] font-semibold ">
            {getCartTranslation("yourData")}
          </p>
          <div className="mt-5 w-full px-4 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-6">
            <div class="col-span-1 md:col-span-2 ">
              <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getBrandTranslation("name")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                    placeholder="Emri"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getBrandTranslation("surname")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                    placeholder={getBrandTranslation("surname")}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Email
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getBrandTranslation("phone")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                    placeholder="Numri i Telefonit"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getBrandTranslation("company")} ({" "}
                    {getBrandTranslation("optional")})
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                    placeholder={`${getBrandTranslation(
                      "company"
                    )} ${getBrandTranslation("optional")}`}
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    NIPT( {getBrandTranslation("optional")})
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                    placeholder={`NIPT ${getBrandTranslation("optional")}`}
                  />
                </div>
                <div className="col-span-2">
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    {getBrandTranslation("address")}
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                    placeholder={getBrandTranslation("name")}
                  />
                </div>
                <div className="col-span-2 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-5">
                  <div>
                    <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                      Shteti
                    </p>
                    <input
                      className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                      placeholder="Shteti"
                    />
                  </div>
                  <div>
                    <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                      {getBrandTranslation("city")}
                    </p>
                    <input
                      className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                      placeholder={getBrandTranslation("city")}
                    />
                  </div>
                  <div>
                    <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                      Zip
                    </p>
                    <input
                      className="w-full h-[36px] px-2 rounded-[5px] border border-[#198754]"
                      placeholder="Zip"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-1">
              <div class="w-full rounded bg-[#F2F2F2] p-4 flex flex-col justify-start items-start">
                <div className="w-full flex justify-between items-start">
                  <h2 className="uppercase text-[16px] text-[#000] font-semibold leading-[24px]">
                    {getCartTranslation("subtotal")}
                  </h2>
                  <h2 className="text-[16px] text-[#000] font-semibold leading-[24px]">
                    LEK {product?.sale_price}
                  </h2>
                </div>
                <div className="mt-3 w-full flex justify-between items-center">
                  <div className="flex justify-center items-center">
                    <img alt="" src={ImgGed} className="w-[35px] h-[35px]" />
                    <div className="ml-2">
                      <h2 className="text-[14px] text-[#000] font-bold leading-[18px]">
                        Ged Normale
                      </h2>
                      <p className="text-[12px] leading-[16px] text-[#000]">
                        Koha e Dorezimit: ~24 Ore
                      </p>
                    </div>
                  </div>
                  <h2 className="text-[16px] text-[#000] font-semibold leading-[24px]">
                    LEK {product?.sale_price}
                  </h2>
                </div>
                <div className="mt-4 w-full flex justify-between items-start">
                  <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                    {getCartTranslation("total")}
                  </h2>
                  <div className="flex flex-col justify-end items-end">
                    <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                      LEK {product?.sale_price}
                    </h2>
                    <p className="mt-2 text-[11px] text-[#000] leading-[14px] uppercase">
                      {getBrandTranslation("reward")} 160{" "}
                      {getBrandTranslation("points")}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className=" mt-4 rounded bg-[#000] w-full h-[45px]  font-bold text-[14px] text-white uppercase"
                onClick={onSubmit}
              >
                {getBrandTranslation("continue")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCartUserInfo;
