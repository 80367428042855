import axios from "axios";
import store from "../redux/store";
import { isEmpty } from "./common";
import { API_BOOST_CORE_URL, APP_KEY } from "../constants/config";

export default async (
  api,
  payload = {},
  token,
  responseType,
  hasToken = false
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { "Content-Type": api.content_type };
      if (
        (api.has_token == true || hasToken === true) &&
        (token != null || store.getState().auth.access_token != null)
      ) {
        headers["Authorization"] =
          "Bearer " + (token ?? store.getState().auth.access_token);
      }

      const url =
        `${API_BOOST_CORE_URL}${api.path}?SN-BOOST-CORE-WEB-API-KEY=${APP_KEY}` +
        (!isEmpty(api.query) ? "&" + api.query : "");

      const response = await axios({
        url,
        method: api.method,
        data: payload,
        headers,
        responseType,
      });

      resolve(response.data);
    } catch (error) {
      reject(error?.response?.data);
    }
  });
};
