import {
  SET_BYBESTLANGUAGE,
  SET_LANGUAGE,
  SET_SHOW_TOP_BTN,
} from '../actions/types';

const initialState = {
  language: 'en',
  showTopBtn: false,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, language: action.payload || 'en' };
    case SET_BYBESTLANGUAGE:
      return { ...state, bybestLanguage: action.payload || 'al' };
    case SET_SHOW_TOP_BTN:
      return { ...state, showTopBtn: action.payload || false };
    default:
      return state;
  }
};

export default app;
