import React from "react";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import AppDownload from "./components/download-app";

const DownloadMobileApp = () => {
  return (
    <BBMainLayout clientKey="bybest">
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] uppercase ">
            BYBEST {`>`} Download mobile app
          </p>
        </div>
        <div className="w-full max-w-screen-main mt-7 mb-5">
          <AppDownload />
        </div>
      </div>
    </BBMainLayout>
  );
};

export default DownloadMobileApp;
