import { FaChevronDown } from "react-icons/fa";

export const CollapseMenu = ({ data, isOpened, onSelect }) => {
  return (
    <>
      <div
        className="cursor-pointer w-full flex justify-center items-center px-4 py-2 bg-[#f5f5f5] border border-[#E6E6E6]"
        onClick={() => onSelect(data)}
      >
        <div className="flex-1">
          <div className="no-underline text-[#000] text-[14px] font-bold w-full uppercase">
            {data.label}
          </div>
        </div>
        <FaChevronDown size={18} color={"#000"} />
      </div>
      {isOpened && <div className="w-full p-3 bg-[white]">{data.content}</div>}
    </>
  );
};
