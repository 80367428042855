import { DatePicker, Modal } from "antd";
import React from "react";
import "../rental/rental-form/index.css";

const RentalReserveModal = ({
  openModal,
  handleCloseModal,
  handleSaveModal,
  modalCheckIn,
  modalCheckOut,
  show,
  setModalCheckIn,
  setModalCheckOut,
  setCheckIn,
  setCheckOut,
}) => {
  return (
    <Modal
      // title="Add a New Card"
      centered
      open={openModal}
      onCancel={() => handleCloseModal()}
      onOk={() => handleSaveModal()}
      maskClosable={false}
      okText="Save"
      okButtonProps={{
        style: { backgroundColor: "#6DDFE2", color: "white" },
        disabled: !modalCheckIn || !modalCheckOut,
      }}
      cancelButtonProps={{
        style: {
          backgroundColor: "transparent",
          color: "#240b3b",
          borderColor: "#6DDFE2",
        },
      }}
      width={680}
    >
      <div className={"flex flex-col py-4 w-full h-max md:h-[450px]"}>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full flex-col gap-4 md:gap-10 ">
          <div className="flex flex-col">
            <p className="text-12 text-primary1 font-bold leading-[16px] mb-2">
              CHECK-IN
            </p>
            <DatePicker
              className="hidden md:block"
              open={show}
              onChange={(e) => {
                setCheckIn(e);
                setModalCheckIn(e);
              }}
            />
            <DatePicker
              className="block md:hidden"
              // defaultValue={dayjs(formatDate(checkIn), 'YYYY-MM-DD')}
              onChange={(e) => {
                setCheckIn(e);
                setModalCheckIn(e);
              }}
            />
          </div>
          <div className="flex flex-col">
            <p className="text-12 text-primary1 font-bold leading-[16px] mb-2">
              CHECK-OUT
            </p>
            <DatePicker
              className="hidden md:block"
              // value={dayjs(formatDate(checkOut), 'YYYY-MM-DD')}
              open={show}
              onChange={(e) => {
                setCheckOut(e);
                setModalCheckOut(e);
              }}
            />
            <DatePicker
              className="block md:hidden"
              // defaultValue={dayjs(formatDate(checkOut), 'YYYY-MM-DD')}
              onChange={(e) => {
                setCheckOut(e);
                setModalCheckOut(e);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RentalReserveModal;
