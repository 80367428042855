import React, { useEffect, useState } from "react";
import "./index.css";
import FormTextInput from "../../components/FormTextInput";
import { NotificationManager } from "react-notifications";
import { Seo } from "../../components/Seo";
import HomeIntegration from "../home/integration";
import { t } from "i18next";
import {
  getStorageKey,
  KEYS,
  removeStorageKey,
} from "../../utils/storage";
import { CircularProgress, FormControlLabel } from "@mui/material";
import { Checkbox } from "antd";
import { joinVenueBoost } from "../../redux/actions/onboarding";
import { useSearchParams } from "react-router-dom";

import Switching from "./switching";
import BusinessWorldwide from "./business-worldwide";

const GetStarted = () => {
  const [data, setData] = useState({
    affiliate_code: null,
    referral_code: null,
    promo_code: null,
    representative_first_name: "",
    representative_last_name: "",
    email: "",
    subscribe: false,
  });

  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const referral_code = getStorageKey(KEYS.REFERRAL_CODE);
  const affiliate_code = getStorageKey(KEYS.AFFILIATE_CODE);

  useEffect(() => {
    if (searchParams.get("promo_code")) {
      setPromoCode(searchParams.get("promo_code"));
    }

    window.scrollTo(0, 0);

    // Initialize reCAPTCHA
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LfMHVgpAAAAAMsm_3myP1DkwC2R4TGlBZuoi8eA";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.grecaptcha.ready(() => {
        setCaptchaLoaded(true);
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [searchParams]);

  const handleCheckboxChange = (e) => {
    setData((prevData) => ({ ...prevData, subscribe: e.target.checked }));
  };

  const executeCaptcha = () => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        reject(new Error("reCAPTCHA not loaded"));
        return;
      }

      window.grecaptcha.execute("6LfMHVgpAAAAAMsm_3myP1DkwC2R4TGlBZuoi8eA", { action: "submit" })
          .then(resolve, reject);
    });
  };

  const handleSubmit = async () => {
    if (!captchaLoaded) {
      NotificationManager.warning("Please wait for reCAPTCHA to load", "Warning", 3000);
      return;
    }

    if (!data.representative_first_name) {
      NotificationManager.warning("Business representative First name field is required", "Warning", 3000);
      return;
    }

    if (!data.representative_last_name) {
      NotificationManager.warning("Business representative Last name field is required", "Warning", 3000);
      return;
    }

    if (!data.email) {
      NotificationManager.warning("Business Email is required", "Warning", 3000);
      return;
    }

    if (!/\S+@\S+\.\S+/.test(data.email)) {
      NotificationManager.warning("Invalid email format", "Warning", 3000);
      return;
    }

    setSubmitting(true);

    try {
      const token = await executeCaptcha();
      await onSubmitRegistration(token);
    } catch (error) {
      console.error("CAPTCHA verification failed:", error);
      NotificationManager.error("CAPTCHA verification failed. Please try again.", "Error", 3000);
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitRegistration = async (captchaToken) => {
    const submissionData = {
      ...data,
      referral_code: referral_code || data.referral_code,
      affiliate_code: affiliate_code || data.affiliate_code,
      promo_code: promoCode || data.promo_code,
      captcha_token: captchaToken,
    };

    try {
      await joinVenueBoost(submissionData);
      setData({
        affiliate_code: null,
        referral_code: null,
        promo_code: null,
        representative_first_name: "",
        representative_last_name: "",
        email: "",
        subscribe: false,
      });
      NotificationManager.success(
          "We've sent a confirmation email to your inbox. Please click the link to confirm your email and start VenueBoost Onboarding Process.",
          "Success",
          5000
      );
      removeStorageKey(KEYS.REFERRAL_CODE);
      removeStorageKey(KEYS.AFFILIATE_CODE);
    } catch (err) {
      const errorKeys = Object.keys(err?.error || {});
      if (errorKeys.length > 0) {
        const firstErrorKey = errorKeys[0];
        const firstErrorMessage = err?.error[firstErrorKey][0];
        NotificationManager.error(firstErrorMessage || "Something went wrong!");
      } else {
        NotificationManager.error(
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io."
        );
      }
    }
  };

  const currentUrl = "https://venueboost.io/get-started";
  const seoTitle = "Unlock Free Plan & Transform Your Business";
  const seoDescription =
      "Explore the potential of VenueBoost with our free plan. Transform your business with seamless operations, personalized insights, and continuous support. Start your journey today!";

  return (
      <div className={"align-col-middle "}>
        <Seo
            title={seoTitle + " | VenueBoost"}
            description={seoDescription}
            url={currentUrl}
        />
        <div className=" w-[100vw] bg-[#F3F3F3] overflow-hidden">
          <div className="relative">
            <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16 z-10">
              <div className=" pt-20 md:flex-row flex-col pb-11 h-full grid grid-cols-1 md:grid-cols-2 ">
                <div className="w-full flex flex-col md:pr-11">
                  <div className="bg-white p-8 rounded-[20px] shadow-sm flex flex-col gap-4 box-shadow-form z-10">
                    <div className="text-[#5D1D82] text-44 font-extrabold leading-[48px]">
                      {t("get_started.readyToTransform")}
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col md:flex-row gap-8">
                        <FormTextInput
                            name="representative_first_name"
                            placeholder="First Name"
                            value={data.representative_first_name}
                            onChange={handleChange}
                            required={true}
                        />

                        <FormTextInput
                            name="representative_last_name"
                            placeholder="Last Name"
                            value={data.representative_last_name}
                            onChange={handleChange}
                            required={true}
                        />
                      </div>
                      <div>
                        <FormTextInput
                            name="email"
                            type={"email"}
                            placeholder="Email"
                            value={data.email}
                            onChange={handleChange}
                            required={true}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  name="subscribe"
                                  checked={data.subscribe}
                                  onChange={handleCheckboxChange}
                                  style={{
                                    fontSize: "16px",
                                    marginRight: "10px",
                                    marginLeft: "10px",
                                  }}
                              />
                            }
                            label={
                                "\n" +
                                "I would like to subscribe to emails from VenueBoost"
                            }
                            style={{ fontSize: "16px" }}
                        />
                      </div>
                      <div className="w-full ndx bottom-[58px] left-0">
                        <button
                            disabled={submitting || !captchaLoaded}
                            className="submit-button font-normal h-auto py-2 md:py-4 md:px-6 text-[13px] md:text-18 rounded-[5px] text-white transition ease-in-out hover:scale-101 w-full bg-gradient-to-r from-[#240B3B] via-[#601B86] to-[#BC14FE]"
                            onClick={handleSubmit}
                        >
                          {submitting ? (
                              <CircularProgress size={16} />
                          ) : (
                              t("get_started.startRegistration")
                          )}
                        </button>
                      </div>

                      <div className="w-full align-middle bottom-[30px] mt-0 left-0 text-16">
                        <p>
                          {t("get_started.registrationAgreement")}
                          &nbsp;
                          <a
                              href="/terms-conditions"
                              style={{
                                textDecoration: "underline",
                              }}
                          >
                            {t("get_started.termsOfService")}
                          </a>{" "}
                          and &nbsp;
                          <a
                              href="/privacy-policy"
                              style={{
                                textDecoration: "underline",
                              }}
                          >
                            {t("get_started.privacyPolicy")}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex flex-col justify-center items-start p-4 gap-8">
                  <div className="  text-28 md:text-[48px] font-bold md:leading-[68px] xl:w-[426px]">
                    {t("get_started.discoverPotential")}
                  </div>

                  <div className="flex flex-col gap-4">
                    <div className="text-[#240B3B] text-20 font-normal leding-[28px]">
                      {t("get_started.embraceVenueBoost")}{" "}
                      {t("get_started.beAtTheForefront")}{" "}
                      {t("get_started.activeParticipation")}{" "}
                      {t("get_started.continuousImprovement")}{" "}
                    </div>
                    <div className="text-[#240B3B] text-20 font-normal leding-[28px]">
                      {t("get_started.fillOutForm")}{" "}
                      {t("get_started.startAdventure")}{" "}
                      {t("get_started.excitedToCollaborate")}{" "}
                      {t("get_started.seeEvolutionUnfold")}{" "}
                    </div>
                  </div>
                </div>
              </div>

              <Switching />
            </div>
          </div>
        </div>
        <div className=" w-[100vw] bg-white">
          <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <HomeIntegration />
            <BusinessWorldwide />
          </div>
        </div>
      </div>
  );
};

export default GetStarted;