import React from "react";
import ByBestSidebar from "./sideBar";
import useSeoData from "../../services/hooks/use-seo";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import { getBrandTranslation } from "./brands/brands";
import { getAboutTranslation } from "./about";
import { t } from "i18next";

export const getPayTranslation = (label = "") => t(`bybest.payment.${label}`);

const ByBestPaymentMethod = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="uppercase lg:text-24 leading-[36px] md:text-16 md:leading-[24px] text-center text-[#000] ">
            {getBrandTranslation("breadcrumb")} {getAboutTranslation("page")}{" "}
            {">"} {getPayTranslation("title")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                {getPayTranslation("payForm")}
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                PAYPAL
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                {getPayTranslation("paypal")}
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPayTranslation("delivery")}
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                {getPayTranslation("deliveryDescription")}
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPayTranslation("creditCard")}
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                {getPayTranslation("creditCardDescription")}
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                {getPayTranslation("bankTransfer")}
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                {getPayTranslation("bankTransferDescription")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestPaymentMethod;
