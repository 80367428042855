import SophiaNAvatar from "../../../assets/svgs/sophia-vb-testimonial.png";
import EmilyMAvatar from "../../../assets/svgs/emily-vb-testimonial.png";
import JoseGAvatar from "../../../assets/svgs/jose-vb-testimonial.png";
import AidenRAvatar from "../../../assets/svgs/aiden-vb-testimonial.png";
import { t } from "i18next";

export const getGrowTranslation = (label = "") =>
  t(`home.testimonials.${label}`);

export const list = () => [
  {
    title: getGrowTranslation("bookingsMadeEasy.title"),
    content: getGrowTranslation("bookingsMadeEasy.content"),
    avatar: AidenRAvatar,
    owner: getGrowTranslation("bookingsMadeEasy.owner"),
    role: getGrowTranslation("bookingsMadeEasy.role"),
    testimonial: getGrowTranslation("bookingsMadeEasy.testimonial"),
  },
  {
    title: getGrowTranslation("inventoryAlwaysRight.title"),
    content: getGrowTranslation("inventoryAlwaysRight.content"),
    avatar: JoseGAvatar,
    owner: getGrowTranslation("inventoryAlwaysRight.owner"),
    role: getGrowTranslation("inventoryAlwaysRight.role"),
    testimonial: getGrowTranslation("inventoryAlwaysRight.testimonial"),
  },
  {
    title: getGrowTranslation("teamsPerfectlyTuned.title"),
    content: getGrowTranslation("teamsPerfectlyTuned.content"),
    avatar: EmilyMAvatar,
    owner: getGrowTranslation("teamsPerfectlyTuned.owner"),
    role: getGrowTranslation("teamsPerfectlyTuned.role"),
    testimonial: getGrowTranslation("teamsPerfectlyTuned.testimonial"),
  },
  {
    title: getGrowTranslation("promotionsThatHit.title"),
    content: getGrowTranslation("promotionsThatHit.content"),
    avatar: JoseGAvatar,
    owner: getGrowTranslation("promotionsThatHit.owner"),
    role: getGrowTranslation("promotionsThatHit.role"),
    testimonial: getGrowTranslation("promotionsThatHit.testimonial"),
  },
  {
    title: getGrowTranslation("loyaltyNaturallyBuilt.title"),
    content: getGrowTranslation("loyaltyNaturallyBuilt.content"),
    avatar: SophiaNAvatar,
    owner: getGrowTranslation("loyaltyNaturallyBuilt.owner"),
    role: getGrowTranslation("loyaltyNaturallyBuilt.role"),
    testimonial: getGrowTranslation("loyaltyNaturallyBuilt.testimonial"),
  },
  {
    title: getGrowTranslation("decisionsDataDriven.title"),
    content: getGrowTranslation("decisionsDataDriven.content"),
    avatar: AidenRAvatar,
    owner: getGrowTranslation("decisionsDataDriven.owner"),
    role: getGrowTranslation("decisionsDataDriven.role"),
    testimonial: getGrowTranslation("decisionsDataDriven.testimonial"),
  },
  {
    title: getGrowTranslation("paymentsClickAndSecure.title"),
    content: getGrowTranslation("paymentsClickAndSecure.content"),
    avatar: SophiaNAvatar,
    owner: getGrowTranslation("paymentsClickAndSecure.owner"),
    role: getGrowTranslation("paymentsClickAndSecure.role"),
    testimonial: getGrowTranslation("paymentsClickAndSecure.testimonial"),
  },
  {
    title: getGrowTranslation("brandingUniquelyYours.title"),
    content: getGrowTranslation("brandingUniquelyYours.content"),
    avatar: JoseGAvatar,
    owner: getGrowTranslation("brandingUniquelyYours.owner"),
    role: getGrowTranslation("brandingUniquelyYours.role"),
    testimonial: getGrowTranslation("brandingUniquelyYours.testimonial"),
  },
];
