import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BybestHomeNotiModal = ({ showModal, onClose }) => {
  const { app_key = "BYB2802APPY" } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [timeLeft, setTimeLeft] = useState(900);
  const { t } = useTranslation();

  const getTranslation = (label = "") => t(`bybest.homeNotiModal.${label}`);

  const offers = [
    t("bybest.homeNotiModal.offer1"),
    t("bybest.homeNotiModal.offer2"),
    t("bybest.homeNotiModal.offer3"),
  ];

  useEffect(() => setOpen(showModal), [showModal]);

  useEffect(() => {
    if (open && timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [open, timeLeft]);

  const onGoRegister = () => {
    navigate(`/register`, { state: { email } });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Modal
      centered
      footer={null}
      wrapClassName="bybest-noti"
      open={open}
      onCancel={onClose}
      closeIcon={null}
    >
      <div className="flex flex-col justify-center items-center w-full">
        <button className="absolute top-3 right-3 text-black" onClick={onClose}>
          <CloseOutlined style={{ fontSize: "20px" }} />
        </button>

        <div className="flex justify-center items-center w-full py-5 px-4 border-b border-b-[#DEE2E6]">
          <h1 className="text-[18px] leading-[24px] md:text-[30px] md:leading-[45px] font-bold text-[#000]">
            {getTranslation("welcomeMessage")}
          </h1>
        </div>
        <div className="w-full px-4 md:px-8">
          <h3 className="mt-4 md:mt-8 text-[16px] leading-[20px] md:text-[22px] md:leading-[26px] font-bold text-[#000]">
            {getTranslation("registerNow")}
          </h3>
          <ul className="mt-3 pl-8 list-disc">
            {offers.map((item, i) => (
              <li
                key={i}
                className="py-1 text-[14px] leading-[16px] md:text-[18px] md:leading-[18px] text-[#000]"
              >
                {item}
              </li>
            ))}
          </ul>
          <input
            className="mt-3 px-3 w-full h-[48px] rounded-[4px] border border-[#CED4DA] outline-none focus:outline-none"
            placeholder={getTranslation("emailPlaceholder")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <button
            className="mt-5 w-full h-[48px] rounded-[4px] bg-[#CB0000] text-white text-[14px] leading-[22px] md:text-[18px] md:leading-[27px]"
            onClick={onGoRegister}
          >
            {getTranslation("registerButton")}
          </button>
        </div>
        <div className="mt-5 px-6 py-5 border-t border-t-[#DEE2E6] flex justify-center items-center text-[14px] leading-[22px] md:text-[17px] md:leading-[26px] text-[#000]">
          {getTranslation("expiresIn")}{" "}
          <span className="text-[#CB0000]">{formatTime(timeLeft)}</span>
        </div>
      </div>
    </Modal>
  );
};

export default BybestHomeNotiModal;
