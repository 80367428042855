import React from 'react';
import { Helmet } from 'react-helmet-async';

const MainHomeHead = ({ seo = {} }) => {
    const defaultSEO = {
        title: "VenueBoost | Simplify Venue Management with Streamlined Solutions",
        description: "VenueBoost | Simplify venue management with streamlined solutions for reservations, operations, and guest experiences.",
        ogImage: "https://venueboost.io/venueboost-saas.png",
        ogUrl: "https://venueboost.io/",
        siteName: "VenueBoost",
        locale: "en_US",
        twitterHandle: "@VenueBoostInc",
        keywords: "venue management, streamlined solutions, reservations, operations, guest experiences",
    };

    // const mergedSEO = { ...defaultSEO, ...seo };

    return (
        <Helmet prioritizeSeoTags>
            <html lang={defaultSEO.locale.split('_')[0]}/>
            <title>{defaultSEO.title}</title>
            <meta name="description" content={defaultSEO.description}/>
            <meta name="keywords" content={defaultSEO.keywords}/>
            <link rel="canonical" href={defaultSEO.ogUrl}/>

            {/* Open Graph */}
            <meta property="og:title" content={defaultSEO.title}/>
            <meta property="og:description" content={defaultSEO.description}/>
            <meta property="og:image" content={defaultSEO.ogImage}/>
            <meta property="og:url" content={defaultSEO.ogUrl}/>
            <meta property="og:type" content="website"/>
            <meta property="og:site_name" content={defaultSEO.siteName}/>
            <meta property="og:locale" content={defaultSEO.locale}/>
            <meta property="og:image:secure_url" content={defaultSEO.ogImage}/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="628"/>
            <meta property="og:image:alt" content="Simplify. Optimize. Grow. with VenueBoost"/>

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content={defaultSEO.twitterHandle}/>
            <meta name="twitter:creator" content={defaultSEO.twitterHandle}/>
            <meta name="twitter:title" content={defaultSEO.title}/>
            <meta name="twitter:description" content={defaultSEO.description}/>
            <meta name="twitter:image" content={defaultSEO.ogImage}/>

            {/* Favicon */}
            <link rel="icon" href="/favicon.ico"/>
            <link rel="icon" href="/icon32.png" sizes="32x32"/>
            <link rel="icon" href="/icon192.png" sizes="192x192"/>
            <link rel="apple-touch-icon" href="/icon180.png"/>

            {/* Insighto.ai chat widget */}
            <script>
                {`const insighto_ai_widget_id = "019028be-40be-784d-8c54-8079bd472d8b"`}
            </script>
            <script defer src="https://cdn.insighto.ai/assets/insighto.min.js"></script>
            <link rel="stylesheet" href="https://cdn.insighto.ai/assets/insighto.min.css" />
        </Helmet>
    );
};

export default MainHomeHead;